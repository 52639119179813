import React from "react";
import { t } from "ttag";

interface Props {
    isRefreshing: boolean;
}

export const TitleText = (props: Props) => {
    if (props.isRefreshing) {
        return <>{t`Your application is processing.`}</>;
    }
    return <>{t`Your application is pending review.`}</>;
};
