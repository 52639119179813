import React from "react";
import classNames from "classnames";
import { IProduct } from "../../../models/catalogue.interfaces";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { ConfiguratorOptions } from "../elements/ConfiguratorOptions";
import { getDefaultConfiguratorOptionSet } from "../utils";

interface IProps {
    // Basic Data
    rootProduct: IProduct | null;

    // Misc UI options
    configType: string;
}

interface IState {}

export class MiniConfigurator extends React.Component<IProps, IState> {
    private buildOptionSelectors() {
        const optionSet = getDefaultConfiguratorOptionSet();
        return (
            <ConfiguratorOptions
                disableQuantity={true}
                style={"mini"}
                optionSet={optionSet}
            />
        );
    }

    private buildConfiguratorContent() {
        if (!this.props.rootProduct) {
            return <LoadingSpinner />;
        }

        return <div>{this.buildOptionSelectors()}</div>;
    }

    private getRootClassNames() {
        return classNames({
            "configurator--mini": true,
            [`${this.props.configType}__configurator--mini`]: true,
        });
    }

    private getProductUPC() {
        return this.props.rootProduct && this.props.rootProduct.upc
            ? this.props.rootProduct.upc
            : "";
    }

    private getChildrenUPCs() {
        const children =
            this.props.rootProduct && this.props.rootProduct.children
                ? this.props.rootProduct.children
                : [];
        return children.map((c) => {
            return c.upc;
        });
    }

    render() {
        const configuratorClass = this.getRootClassNames();
        const productUPC = this.getProductUPC();
        const childrenUPCJSON = JSON.stringify(this.getChildrenUPCs());
        return (
            <div
                className={configuratorClass}
                data-mpn={productUPC}
                data-children-mpns={childrenUPCJSON}
            >
                {this.buildConfiguratorContent()}
            </div>
        );
    }
}
