import React from "react";
import * as Dialog from "@radix-ui/react-dialog";

import styles from "./index.module.scss";
import { FinancingModalHeader } from "../FinancingModalHeader";

export const FinancingRoot = Dialog.Root;

export const FinancingTrigger = Dialog.Trigger;

export const FinancingPortalClose = Dialog.Close;

export const FinancingPortal = (
    props: React.PropsWithChildren<{
        title: string;
    }>,
) => {
    return (
        <Dialog.Portal>
            <Dialog.Overlay className={styles.overlay} />
            <Dialog.Content
                className={styles.container}
                aria-describedby={undefined}
            >
                <FinancingModalHeader title={props.title} />
                <div className={styles.wrapper}>{props.children}</div>
            </Dialog.Content>
        </Dialog.Portal>
    );
};
