import { combineReducers } from "@reduxjs/toolkit";
import {
    isoProductUUID,
    isoSyncStoreID,
} from "tsi-common-react/src/models/nominals";
import { guardReducer } from "tsi-common-react/src/utils/redux";
import { reducersFns as baseReducers } from "tsi-common-react/src/apps/retail/reducers";
import { defaults } from "./defaults";
import { Action, IReduxState, IAction } from "./reducers.interfaces";

const productAvailability = guardReducer(
    Action,
    defaults.productAvailability,
    (
        state = defaults.productAvailability,
        action: IAction,
    ): IReduxState["productAvailability"] => {
        switch (action.type) {
            case Action.SET_PRODUCT_AVAILABILITY:
                return {
                    ...state,
                    [isoProductUUID.unwrap(action.payload.product)]:
                        action.payload.stores.map((s) => {
                            return s.external_id;
                        }),
                };
        }
        return state;
    },
);

const selectedProduct = guardReducer(
    Action,
    defaults.selectedProduct,
    (
        state = defaults.selectedProduct,
        action: IAction,
    ): IReduxState["selectedProduct"] => {
        switch (action.type) {
            case Action.SET_SELECTED_PRODUCT:
                return action.payload;
        }
        return state;
    },
);

const flagshipStorePages = guardReducer(
    Action,
    defaults.flagshipStorePages,
    (
        state = defaults.flagshipStorePages,
        action: IAction,
    ): IReduxState["flagshipStorePages"] => {
        switch (action.type) {
            case Action.ADD_FLAGSHIP_STORE_PAGES:
                const pages = {
                    ...state,
                };
                action.payload.forEach((page) => {
                    pages[isoSyncStoreID.unwrap(page.meta.sync_id)] = page;
                });
                return pages;
        }
        return state;
    },
);

export const reducers = combineReducers({
    ...baseReducers,
    productAvailability: productAvailability,
    selectedProduct: selectedProduct,
    flagshipStorePages: flagshipStorePages,
});
