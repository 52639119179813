import React from "react";
import Modal from "react-modal";
import classNames from "classnames";
import Flickity from "flickity-imagesloaded";
import SVG from "react-inlinesvg";
import { t } from "ttag";
import { focusElement } from "../utils/keyboardFocus";
import { Image } from "./Image";
import {
    detectTileVisibility,
    setAriaHiddenAttribute,
    removeTabindex,
} from "../utils/detectFlickityTileVisibility";
import iconCarouselArrowLeft from "../../img/icons/carousel-arrow-left.svg";
import iconCarouselArrowRight from "../../img/icons/carousel-arrow-right.svg";
import iconXClose from "../../img/icons/x-close.svg";
import iconGalleryArrowLeft from "../../img/icons/gallery-arrow-left.svg";
import iconGalleryArrowRight from "../../img/icons/gallery-arrow-right.svg";

Modal.defaultStyles = {
    overlay: {},
    content: {},
};

export interface IGalleryImage {
    originalURL: string;
    originalAlt: string;
    thumbnailURL: string;
    thumbnailAlt: string;
    caption: string;
}

export interface IProps {
    images: IGalleryImage[];
    alternateArrows?: boolean;
}

interface IState {
    modalIsOpen: boolean;
    selectedSlide: number;
    selectedTilesGroupRange: number;
    selectedTileIndex: number;
    thumbsBuilt: boolean;
}

export class Gallery extends React.Component<IProps, IState> {
    private readonly SMALL_SCREEN_THRESHOLD = 501;
    private readonly MEDIUM_SCREEN_THRESHOLD = 769;
    private thumbCarousel: HTMLDivElement | null = null;
    private imageCarousel: HTMLUListElement | null = null;
    private thumbnailFlkty: Flickity | null = null;
    private carouselFlkty: Flickity | null = null;

    static defaultProps: Partial<IProps> = {
        alternateArrows: false,
    };

    public state: IState = {
        modalIsOpen: false,
        selectedSlide: 1,
        selectedTilesGroupRange: 0,
        selectedTileIndex: 0,
        thumbsBuilt: false,
    };

    private readonly onNavigationClick = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        const direction = event.currentTarget.dataset.direction;
        if (this.carouselFlkty) {
            switch (direction) {
                case "previous":
                    this.carouselFlkty.previous();
                    break;
                case "next":
                    this.carouselFlkty.next();
                    break;
                default:
                    break;
            }
        }
    };

    private readonly openSelectedModalSlide = (
        slideIndex: number,
        event: React.MouseEvent<HTMLElement>,
    ) => {
        if (event) {
            event.preventDefault();
        }

        const newState = {
            modalIsOpen: true,
            selectedSlide: slideIndex,
        };

        this.setState(newState);
    };

    private readonly onCloseModal = (
        event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            modalIsOpen: false,
        });
    };

    private readonly onAfterOpenModal = () => {
        const fullCarouselElem = this.imageCarousel;
        if (!fullCarouselElem) {
            return;
        }

        if (this.carouselFlkty) {
            this.carouselFlkty.destroy();
        }

        this.carouselFlkty = new Flickity(fullCarouselElem, {
            cellAlign: "left",
            pageDots: false,
            prevNextButtons: false,
            imagesLoaded: true,
        });

        this.carouselFlkty.select(this.state.selectedSlide, true, true);
        this.carouselFlkty.focus();
        this.carouselFlkty.on("change", () => {
            if (this.carouselFlkty) {
                this.setState({
                    selectedSlide: this.carouselFlkty.selectedIndex,
                });
            }
        });

        // ADA - Remove tabindex attribute which is added by flickity to avoid problems
        // for screen reader and keyboard users by disrupting the natural tab order
        const galleryModalContent = document.querySelector(
            ".pdp-gallery-modal__content",
        );
        if (galleryModalContent) {
            galleryModalContent.removeAttribute("tabindex");
        }

        focusElement(".pdp-gallery-modal__close");
    };

    private readonly onCarouselClick = (direction: string) => {
        if (this.thumbnailFlkty) {
            if (direction === "prev") {
                this.thumbnailFlkty.previous();
            } else if (direction === "next") {
                this.thumbnailFlkty.next();
            }
        }
    };

    componentDidMount() {
        const thumbCarouselElem = this.thumbCarousel;
        if (!thumbCarouselElem) {
            return;
        }

        this.recalcFlickityDisplay(thumbCarouselElem);
        window.addEventListener("resize", () => {
            this.recalcFlickityDisplay(thumbCarouselElem);
        });
    }

    componentDidUpdate() {
        if (this.carouselFlkty) {
            this.carouselFlkty.select(this.state.selectedSlide);
        }

        setAriaHiddenAttribute(
            "pdp-gallery-thumbs__item",
            "pdp-gallery-thumbs__item--hidden",
        );

        // ADA - Remove tabindex attribute which is added by flickity to avoid problems
        // for screen reader and keyboard users by disrupting the natural tab order
        removeTabindex("pdp-gallery-thumbs__list");
    }

    private checkDisplayTilesNumber() {
        const displayTilesNumber = !(
            window.innerWidth < this.MEDIUM_SCREEN_THRESHOLD
        )
            ? 5
            : !(window.innerWidth < this.SMALL_SCREEN_THRESHOLD)
              ? 4
              : 2;
        return displayTilesNumber;
    }

    private recalcFlickityDisplay(thumbCarouselElem: HTMLDivElement) {
        const isSmallBreakpoint =
            window.innerWidth < this.SMALL_SCREEN_THRESHOLD;
        const isMediumBreakpoint =
            window.innerWidth < this.MEDIUM_SCREEN_THRESHOLD;
        const numberOfSlides = this.props.images.length;
        if (
            (isSmallBreakpoint && numberOfSlides > 2) ||
            (isMediumBreakpoint && numberOfSlides > 4) ||
            numberOfSlides > 5
        ) {
            this.initThumbnailFlickity(thumbCarouselElem);
        } else {
            if (this.thumbnailFlkty) {
                this.thumbnailFlkty.destroy();
            }
        }
        this.onAfterOpenModal();
    }

    private initThumbnailFlickity(carousel: HTMLDivElement) {
        if (this.thumbnailFlkty) {
            return;
        }

        this.thumbnailFlkty = new Flickity(carousel, {
            cellSelector: ".gallery__item",
            cellAlign: "left",
            contain: true,
            groupCells: true,
            pageDots: false,
            imagesLoaded: true,
            prevNextButtons: !this.props.alternateArrows,
        });

        this.thumbnailFlkty.on("select", () => {
            if (this.thumbnailFlkty) {
                this.setState({
                    selectedTilesGroupRange:
                        Number(this.thumbnailFlkty.selectedIndex) +
                        Number(this.checkDisplayTilesNumber() - 1),
                    selectedTileIndex: this.thumbnailFlkty.selectedIndex,
                });
            }
        });
    }

    private buildSlideDotButtons() {
        const dots = this.props.images.map((img, index) => {
            const classes = classNames({
                "is-selected": index === this.state.selectedSlide,
            });
            const slideNum = index + 1;
            return (
                <button
                    aria-current={index === this.state.selectedSlide}
                    aria-label={t`Slide ${slideNum} of ${this.props.images.length}, ${
                        img.originalAlt
                    }`}
                    className={classes}
                    key={`${index}`}
                    onClick={this.openSelectedModalSlide.bind(this, index)}
                ></button>
            );
        });

        return <div className="pdp-gallery-modal__dots">{dots}</div>;
    }

    private buildThumbs() {
        const tilesNumber = this.props.images.length;
        let isTileInViewport = true;
        const images = this.props.images.map((img, index) => {
            if (this.thumbnailFlkty) {
                isTileInViewport = detectTileVisibility(
                    tilesNumber,
                    index,
                    this.state.selectedTilesGroupRange,
                    this.state.selectedTileIndex,
                );
            }

            const classes = classNames({
                "gallery__item": true,
                "pdp-gallery-thumbs__item": true,
                "pdp-gallery-thumbs__item--hidden": !isTileInViewport,
                "al-pdp-gallery__thumbnail": true,
            });
            const slideNum = index + 1;
            return (
                <button
                    tabIndex={!isTileInViewport ? -1 : 0}
                    aria-label={t`Slide ${slideNum} of ${this.props.images.length}, ${
                        img.thumbnailAlt
                    }`}
                    className={classes}
                    key={index}
                    onClick={this.openSelectedModalSlide.bind(this, index)}
                >
                    <Image src={img.thumbnailURL} alt={img.thumbnailAlt} />
                </button>
            );
        });

        // This this.state.thumbsBuilt is used to ensures the custom next arrow element is placed
        // after the gallery thumb elements in the HTML
        // so that the custom arrow elements receive focus in an logical order for screen reader
        if (!this.state.thumbsBuilt) {
            this.setState({
                thumbsBuilt: true,
            });
        }
        return images;
    }

    private buildFullImages() {
        const images = this.props.images.map((img, i) => {
            return (
                <li
                    key={i}
                    className="gallery__item pdp-gallery-modal-item"
                    data-src={img.originalURL}
                    data-thumb={img.originalURL}
                >
                    <div className="pdp-gallery-modal-item__container">
                        <Image src={img.originalURL} alt={img.originalAlt} />
                    </div>
                    <div className="pdp-gallery-modal-item__info">
                        <div
                            className="pdp-gallery-modal-item__caption"
                            dangerouslySetInnerHTML={{ __html: img.caption }}
                        ></div>
                    </div>
                </li>
            );
        });
        return images;
    }

    render() {
        const classes = classNames({
            "gallery": true,
            "u-margin-bottom--xl": true,
        });
        const modalStyle = {
            overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
                paddingTop: "35px",
                paddingBottom: "35px",
                overflow: "hidden",
                boxShadow: "none",
                zIndex: 99999,
            },
        };
        return (
            <div className={classes}>
                <div role="region" aria-label="carousel">
                    <div
                        className="pdp-gallery-thumbs__list"
                        ref={(ref) => {
                            this.thumbCarousel = ref;
                        }}
                    >
                        {this.props.alternateArrows && (
                            <button
                                className="pdp-gallery-thumbs__arrow-container pdp-gallery-thumbs__arrow-container--left"
                                aria-label={t`Previous`}
                                onClick={this.onCarouselClick.bind(
                                    this,
                                    "prev",
                                )}
                            >
                                <SVG
                                    aria-hidden="true"
                                    className="pdp-gallery-thumbs__arrow"
                                    src={iconCarouselArrowLeft}
                                />
                            </button>
                        )}
                        {this.buildThumbs()}
                        {this.props.alternateArrows &&
                            this.state.thumbsBuilt && (
                                <button
                                    className="pdp-gallery-thumbs__arrow-container pdp-gallery-thumbs__arrow-container--right"
                                    aria-label={t`next`}
                                    onClick={this.onCarouselClick.bind(
                                        this,
                                        "next",
                                    )}
                                >
                                    <SVG
                                        aria-hidden="true"
                                        className="pdp-gallery-thumbs__arrow"
                                        src={iconCarouselArrowRight}
                                    />
                                </button>
                            )}
                    </div>
                </div>
                <Modal
                    aria={{
                        modal: true,
                        labelledby: "heading",
                    }}
                    className="pdp-gallery-modal"
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.onAfterOpenModal}
                    onRequestClose={this.onCloseModal}
                    role="dialog"
                    style={modalStyle}
                >
                    <h1 id="heading" className="ada-screenreader-only">
                        Gallery of product images
                    </h1>
                    <button
                        aria-label={t`Close`}
                        className="pdp-gallery-modal__close al-pdp-gallery-modal__close al-pdp-gallery__close"
                        onClick={this.onCloseModal}
                        type="button"
                    >
                        <SVG
                            aria-hidden="true"
                            className="pdp-gallery-modal__close-icon"
                            role="img"
                            src={iconXClose}
                            title={t`Close`}
                        />
                    </button>
                    <div
                        role="region"
                        aria-label={t`Carousel`}
                        className="pdp-gallery-modal__content-container"
                    >
                        <div className="pdp-gallery-modal__buttons pdp-gallery-modal-item__container">
                            <button
                                aria-label={t`Previous`}
                                className="pdp-gallery-modal__button pdp-gallery-modal__button--previous al-pdp-gallery-modal__left"
                                data-direction="previous"
                                onClick={this.onNavigationClick}
                                type="button"
                            >
                                <SVG
                                    aria-hidden="true"
                                    className="pdp-gallery-modal__button-icon pdp-gallery-modal__button-icon--previous"
                                    src={iconGalleryArrowLeft}
                                    role="img"
                                    title={t`Arrow left`}
                                />
                            </button>
                        </div>
                        <ul
                            className="pdp-gallery-modal__content"
                            ref={(ref) => {
                                this.imageCarousel = ref;
                            }}
                        >
                            {this.buildFullImages()}
                        </ul>
                        {this.buildSlideDotButtons()}
                        <div className="pdp-gallery-modal__buttons pdp-gallery-modal-item__container">
                            <button
                                aria-label={t`Next`}
                                className="pdp-gallery-modal__button pdp-gallery-modal__button--next al-pdp-gallery-modal__right"
                                data-direction="next"
                                onClick={this.onNavigationClick}
                                type="button"
                            >
                                <SVG
                                    aria-hidden="true"
                                    className="pdp-gallery-modal__button-icon pdp-gallery-modal__button-icon--next"
                                    src={iconGalleryArrowRight}
                                    role="img"
                                    title={t`Arrow right`}
                                />
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Gallery;
