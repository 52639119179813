import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { IBasket } from "../../../models/catalogue.interfaces";
import { TStateMapper } from "../../reducers.interfaces";
import { ContactUsModalContent } from "./ContactUsModalContent";
import { focusElement } from "../../../utils/keyboardFocus";

interface IOwnProps {
    isOpen: boolean;
    onClose: (
        event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => void;
}

interface IReduxProps {
    basket: IBasket | null;
}

interface IProps extends IOwnProps, IReduxProps {}

class ContactUsModalComponent extends React.Component<IProps> {
    render() {
        const modalStylePhone: Modal.Styles = {
            overlay: {
                zIndex: 200,
            },
            content: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
                padding: "25px",
                overflowX: "hidden",
                overflowY: "auto",
                boxShadow: "none",
                border: "1px solid #c9ced1",
                borderRadius: "10px",
                backgroundColor: "#f7f7f7",
            },
        };

        return (
            <Modal
                contentLabel="Contact Us"
                aria={{ modal: true }}
                style={modalStylePhone}
                className="service-footer-modal-phone"
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onClose}
                onAfterOpen={() => {
                    focusElement(".service-footer-modal__close");
                }}
            >
                <ContactUsModalContent
                    basket={this.props.basket}
                    onCloseModalContact={this.props.onClose}
                />
            </Modal>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        basket: rootState.checkout.data.basket,
        ...ownProps,
    };
};

export const ContactUsModal = connect(mapStateToProps)(ContactUsModalComponent);
