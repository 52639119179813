import React from "react";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "tsi-common-react/src/models/location.interfaces";
import { IStoreSearchFilters } from "tsi-common-react/src/apps/retail/models.interfaces";
import { IFilterChangeHandler } from "../models.interfaces";
import { RetailerFilter } from "./LocatorFilters_Retailer";
import { DistanceFilter } from "./LocatorFilters_Distance";
import { LocationFilter } from "./LocatorFilters_Location";

interface IProps {
    filterValues: IStoreSearchFilters;
    currentLocation: ILocation | null;
    allStores: IRetailStoreWithDistance[];
    filteredStores: IRetailStoreWithDistance[];
    onChange: IFilterChangeHandler;
}

interface IState {}

export class LocatorFilters extends React.Component<IProps, IState> {
    render() {
        return (
            <p className="filters__filters">
                <span className="filters__results">
                    <strong className="bh-sl-total-results">
                        {this.props.filteredStores.length}
                    </strong>{" "}
                    results
                </span>{" "}
                for{" "}
                <RetailerFilter
                    filterValues={this.props.filterValues}
                    onChange={this.props.onChange}
                    stores={this.props.allStores}
                />{" "}
                within{" "}
                <DistanceFilter
                    filterValues={this.props.filterValues}
                    onChange={this.props.onChange}
                />{" "}
                of{" "}
                <LocationFilter
                    filterValues={this.props.filterValues}
                    currentLocation={this.props.currentLocation}
                    onChange={this.props.onChange}
                />{" "}
                that carry Tempur-Pedic products.
            </p>
        );
    }
}
