import React from "react";
import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";
import { t } from "ttag";
import SVG from "react-inlinesvg";
import { Link } from "../../../common/Link";
import {
    IServiceFooterValueProps,
    IServiceFooterValueProp,
} from "../../../models/value-props.interfaces";
import styles from "./ServiceFooterValuePropsModalContent.module.scss";
import iconXClose from "../../../../img/icons/x-close.svg";

interface IProps {
    onCloseModal: (
        event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => void;
    apr: number;
    length: number;
    content: IServiceFooterValueProps;
}

const ServiceFooterModalValueProp = (
    props: IServiceFooterValueProp & { apr: number; length: number },
) => {
    const title = interpolate(props.title, {
        apr: `${props.apr}`,
        length: `${props.length}`,
    });

    let learnMoreLink: JSX.Element | null = null;
    if (props.learn_more_link) {
        learnMoreLink = (
            <Link className={styles.listLink} href={props.learn_more_link.url}>
                {t`Learn More`}
            </Link>
        );
    }

    return (
        <li className={styles.listItem}>
            <div>
                <img src={props.icon.url} />
            </div>
            <div>
                <h5 dangerouslySetInnerHTML={{ __html: title }}></h5>
                <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
                {learnMoreLink}
            </div>
        </li>
    );
};

export class ServiceFooterValuePropsModalContent extends React.Component<IProps> {
    render() {
        const valuePropElems = this.props.content.value_props.map(
            (valueProp, i) => {
                return (
                    <ServiceFooterModalValueProp
                        key={i}
                        apr={this.props.apr}
                        length={this.props.length}
                        {...valueProp}
                    />
                );
            },
        );

        return (
            <div>
                <button
                    className={`${styles.close} service-footer-modal__close`}
                    onClick={this.props.onCloseModal}
                    aria-label={t`close`}
                >
                    <SVG
                        aria-hidden={true}
                        className={styles.closeIcon}
                        src={iconXClose}
                        title={t`Close Icon`}
                    />
                </button>
                <div
                    className={`${styles.title} service-footer-modal-props__title`}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: this.props.content.title,
                        }}
                    ></span>
                    <div className="service-footer-modal-props__title-bar"></div>
                </div>
                <ul className={styles.list}>{valuePropElems}</ul>
            </div>
        );
    }
}
