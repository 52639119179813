import React from "react";
import { t } from "ttag";
import { createRoot } from "react-dom/client";
import { strings } from "../../localization";
import { FormStepType } from "../../models/forms";
import { FieldsSummary } from "../../forms/FieldsSummary";
import { getFields } from "./FinancingFullApplyForm/fields";
import { FullApplicationValues, IndividualSteps, JointSteps } from "./models";

interface ApplyFormPrintProps {
    formSteps: IndividualSteps | JointSteps;
    currStepIndex?: number;
    values: FullApplicationValues;
}

const ApplyFormPrint = (props: ApplyFormPrintProps) => {
    const summarySteps = props.formSteps.map((step, index) => {
        if (!props.currStepIndex || index < props.currStepIndex) {
            switch (step.stepType) {
                case FormStepType.FieldSet:
                    return (
                        <FieldsSummary
                            key={index}
                            step={index}
                            heading={step.groupTitle}
                            fieldOrder={step.fields}
                            fields={getFields(props.values)}
                            values={props.values}
                        />
                    );
                default:
                    return null;
            }
        }
        return null;
    });
    return (
        <div>
            <div className="financing-modal-print__content">
                <h1 className="financing-modal-print__title">
                    {strings.get("FINANCING_PRINT_APPLY_FORM_HEAD")}
                </h1>
                {summarySteps}
            </div>
            <hr />
            <a
                className="financing-modal-print__link"
                href={strings.get("FINANCING_TERMS_PDF") || ""}
                target="_blank"
                rel="noreferrer"
            >
                Account Agreement and Disclosures
            </a>
        </div>
    );
};

export const printSummary = (props: ApplyFormPrintProps) => {
    const width = 640;
    const height = 480;
    const options = [
        ["toolbar", "no"],
        ["location", "no"],
        ["directories", "no"],
        ["status", "no"],
        ["menubar", "no"],
        ["scrollbars", "no"],
        ["resizable", "yes"],
        ["width", width],
        ["height", height],
        ["top", screen.height / 2 - height / 2],
        ["left", screen.width / 2 - width / 2],
    ];
    const encodedOptions = options
        .map((pair) => {
            return pair.join("=");
        })
        .join(", ");
    const popup = window.open(
        "",
        t`Online Credit Application - Printable Review`,
        encodedOptions,
    );
    if (!popup) {
        return;
    }
    if (document.head && popup.document.head) {
        popup.document.head.innerHTML = document.head.innerHTML;
    }
    popup.document.body.innerHTML =
        '<div class="financing-modal-print" id="financing-modal-print"></div>';
    const root = createRoot(
        popup.document.getElementById("financing-modal-print")!,
    );
    root.render(<ApplyFormPrint {...props} />);
    popup.print();
};
