import { decorateElements } from "tsi-common-react/src/utils/react";

decorateElements(".size-guide-block .grid", async (elems) => {
    const Flickity = (await import("flickity")).default;
    for (const sizeGuide of elems) {
        new Flickity(sizeGuide, {
            watchCSS: true,
            draggable: true,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: true,
            groupCells: true,
        });
    }
});
