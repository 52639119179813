import React from "react";
import { Provider } from "react-redux";
import { isoSyncStoreID } from "tsi-common-react/src/models/nominals";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { strToBool } from "tsi-common-react/src/utils/format";
import { readyStateComplete } from "tsi-common-react/src/utils/events";
import { loadGMaps, newMap } from "tsi-common-react/src/utils/maps";
import { store } from "../store";
import { ICarouselCell } from "./components/FlagshipCarousel";
import { ISlideshowCell } from "./components/FlagshipSlideshow";
import iconMarker2 from "../img/retail-locator/icon-marker2.png";

// Flagship All Nearest Stores With Map
dynamicPlaceComponent(
    '[data-place-react="flagship-all-nearest-stores"]',
    async () => {
        const { FlagshipNearestStores } = await import(
            "./containers/FlagshipNearestStores"
        );
        return (
            <Provider store={store}>
                <FlagshipNearestStores
                    maxTiles={99}
                    includeLocationInfo={false}
                />
            </Provider>
        );
    },
);

// Flagship Nearest 4 Stores With Map
dynamicPlaceComponent(
    '[data-place-react="flagship-nearest-stores"]',
    async () => {
        const { FlagshipNearestStores } = await import(
            "./containers/FlagshipNearestStores"
        );
        return (
            <Provider store={store}>
                <FlagshipNearestStores
                    maxTiles={4}
                    includeLocationInfo={true}
                />
            </Provider>
        );
    },
);

// Flagship Info With Map
const renderFlagshipStoreInfoMapComponent = () => {
    // Find this location's SyncID on page
    const location = document.getElementById("flagship-location");
    const storeID = location
        ? isoSyncStoreID.wrap(parseInt(`${location.dataset.syncId}`, 10))
        : null;
    if (!storeID) {
        return;
    }
    // Find the maps mount point on page
    const mapMountElement = document.querySelector<HTMLElement>("#map_canvas");
    if (mapMountElement == null) {
        return;
    }
    dynamicPlaceComponent('[data-place-react="flagship-info"]', async () => {
        const { FlagshipStoreInfoMap } = await import(
            "./containers/FlagshipStoreInfoMap"
        );
        return (
            <Provider store={store}>
                <FlagshipStoreInfoMap
                    storeID={storeID}
                    mapMountElement={mapMountElement}
                />
            </Provider>
        );
    });
};
renderFlagshipStoreInfoMapComponent();

// Render Flagship Map Modal
dynamicPlaceComponent('[data-place-react="flagship-map"]', async () => {
    const { FlagshipMapModal } = await import("./components/FlagshipMapModal");
    const mapById = document.getElementById("flagship-map-data");
    const mapSmallSrc = mapById ? mapById.getAttribute("data-map-small") : "";
    const mapLargeSrc = mapById ? mapById.getAttribute("data-map-large") : "";
    const mapImageAlt = mapById ? mapById.getAttribute("data-map-alt") : "";

    return (
        <FlagshipMapModal
            mapSmallSrc={mapSmallSrc || ""}
            mapLargeSrc={mapLargeSrc || ""}
            mapImageAlt={mapImageAlt || ""}
        />
    );
});

// Render Flagship Reviews Link
dynamicPlaceComponent(
    '[data-place-react="flagship-reviews-link"]',
    async () => {
        const { FlagshipReviewsLink } = await import(
            "./components/FlagshipReviewsLink"
        );
        return <FlagshipReviewsLink />;
    },
);

// Flickity Slideshow - for Flagship Page
dynamicPlaceComponent('[data-place-react="flagship-slideshow"]', async () => {
    const { FlagshipSlideshow } = await import(
        "./components/FlagshipSlideshow"
    );
    const rawBlocks = document.querySelectorAll<HTMLElement>(
        ".flagship-slideshow-data",
    );
    const carouselBlocks = rawBlocks ? Array.from(rawBlocks) : [];
    const slideshowCell = carouselBlocks.map((item): ISlideshowCell => {
        return {
            thumbnailURL: item.dataset.thumbnailUrl || "",
            thumbnail: item.dataset.thumbnail || "",
            headline: item.dataset.headline || "",
            descript: item.innerHTML || "",
            label: item.dataset.label || "",
            styling: item.dataset.styling || "",
        };
    });
    await readyStateComplete;
    return <FlagshipSlideshow images={slideshowCell} />;
});

// Flickity Carousel - for Flagship Page
dynamicPlaceComponent('[data-place-react="flagship-carousel"]', async () => {
    const { FlagshipCarousel } = await import("./components/FlagshipCarousel");
    const rawBlocks = document.querySelectorAll<HTMLElement>(
        ".flagship-carousel-data",
    );
    const carouselBlocks = rawBlocks ? Array.from(rawBlocks) : [];
    const carouselImages = carouselBlocks.map((item): ICarouselCell => {
        return {
            imageURL: item.dataset.imageUrl || "",
            imageTitle: item.dataset.imageTitle || "",
            videoSRC: item.dataset.videoSrc || "",
            label: item.dataset.label || "",
        };
    });
    await readyStateComplete;
    return <FlagshipCarousel images={carouselImages} />;
});

dynamicPlaceComponent('[data-place-react="nearby-stores-ad"]', async () => {
    const { NearbyStoresAd } = await import("./containers/NearbyStoresAd");
    return (
        <Provider store={store}>
            <NearbyStoresAd />
        </Provider>
    );
});

dynamicPlaceComponent('[data-place-react="distance-to-store"]', async () => {
    const { DistanceToStore } = await import("./containers/DistanceToStore");
    return (
        <Provider store={store}>
            <DistanceToStore />
        </Provider>
    );
});

// DistanceToFlagshipStoreComponent mount and DOM sync_id extraction
dynamicPlaceComponent(
    '[data-place-react="distance-to-flagship-store"]',
    async () => {
        const { DistanceToFlagshipStore } = await import(
            "./containers/DistanceToFlagshipStore"
        );
        return (
            <Provider store={store}>
                <DistanceToFlagshipStore />
            </Provider>
        );
    },
);

// Retail Locator Page Quick Search Tiles
dynamicPlaceComponent(
    '[data-place-react="locator-quick-search"]',
    async (elem) => {
        const { LocatorQuickSearch } = await import(
            "./containers/LocatorQuickSearch"
        );
        const selectedItem = elem.dataset.selectedItem;
        const hideElite = strToBool(elem.dataset.hideElite || "");
        return (
            <Provider store={store}>
                <LocatorQuickSearch
                    selectedItem={selectedItem}
                    hideElite={hideElite}
                />
            </Provider>
        );
    },
);

// Retail Locator Filter Bar
dynamicPlaceComponent('[data-place-react="locator-filters"]', async () => {
    const { LocatorFilters } = await import("./containers/LocatorFilters");
    return (
        <Provider store={store}>
            <LocatorFilters />
        </Provider>
    );
});

// Retail Locator Nearest Stores
dynamicPlaceComponent(
    '[data-place-react="locator-nearest-stores"]',
    async () => {
        const { LocatorNearestStores } = await import(
            "./containers/LocatorNearestStores"
        );
        return (
            <Provider store={store}>
                <LocatorNearestStores />
            </Provider>
        );
    },
);

// Retail Locator Send Store Details
dynamicPlaceComponent(
    '[data-place-react="locator-send-store-details"]',
    async (elem) => {
        const { LocatorSendSingleStore } = await import(
            "./containers/LocatorSendSingleStore"
        );
        if (!elem.dataset.store) {
            return null;
        }
        return (
            <Provider store={store}>
                <LocatorSendSingleStore
                    storeID={isoSyncStoreID.wrap(
                        parseInt(elem.dataset.store, 10),
                    )}
                />
            </Provider>
        );
    },
);

// Featured Retailer Locations
dynamicPlaceComponent(
    '[data-place-react="featured-retailer-locations"]',
    async (elem) => {
        const { FeaturedRetailerLocations } = await import(
            "./containers/FeaturedRetailerLocations"
        );
        return (
            <Provider store={store}>
                <FeaturedRetailerLocations
                    configJSON={elem.dataset.config || "null"}
                />
            </Provider>
        );
    },
);

// Activate print button on Store page
document.querySelector<HTMLElement>("#print")?.addEventListener("click", () => {
    window.print();
});

// Activate Google Map Hero on Store page
(async () => {
    const mapCanvas = document.querySelector<HTMLElement>("#map_canvas");
    if (!mapCanvas || !mapCanvas.dataset.lat || !mapCanvas.dataset.lng) {
        return;
    }
    // Load Google Maps API
    await loadGMaps();
    // Init the map
    const storeLat = parseFloat(mapCanvas.dataset.lat);
    const storeLng = parseFloat(mapCanvas.dataset.lng);
    const myLatlng = new google.maps.LatLng(storeLat, storeLng);
    const mapCenter = new google.maps.LatLng(storeLat, storeLng);
    const mapOptions = {
        center: mapCenter,
        zoom: 16,
        scrollwheel: false,
        draggable: true,
        disableDefaultUI: false,
        clickableIcons: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    const map = await newMap(mapCanvas, mapOptions);
    const marker = new google.maps.Marker({
        position: myLatlng,
        title: "Tempur-Pedic Store",
        icon: iconMarker2,
    });
    marker.setMap(map);
    map.set("styles", [
        {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
                { hue: "#ffff00" },
                { saturation: 30 },
                { lightness: 10 },
            ],
        },
    ]);
    // Handle title tag - a11y approved
    google.maps.event.addListenerOnce(map, "idle", () => {
        const storeIframe =
            document.querySelector<HTMLIFrameElement>("#map_canvas iframe");
        if (storeIframe) {
            storeIframe.title = "Google Maps";
        }
    });
})();

// Register store cascades
import "./cascades";
