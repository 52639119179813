import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

const selectFirstErrorField = () => {
    const firstErrorMessage =
        document.querySelector<HTMLElement>(".form__error-list");
    const field = firstErrorMessage?.parentElement
        ?.firstElementChild as HTMLElement | null;
    if (field) {
        field.focus();
    }
};

onDOMContentLoaded.on(() => {
    if (!document.body.classList.contains("contact-us-page")) {
        return;
    }

    // change label of message field based on reason selection
    const reasons = document.getElementById("reasons");
    if (reasons) {
        reasons.addEventListener("change", (e) => {
            const reason: HTMLSelectElement = e.target as HTMLSelectElement;
            const text = reason.options[reason.selectedIndex].text;
            const label = document.querySelector("label[for=message]");
            if (label) {
                if (text === "Unsubscribe from Postal") {
                    label.textContent = "Mailing Address:";
                } else {
                    label.textContent = "Your Message:";
                }
            }
        });
    }

    selectFirstErrorField();
});
