import React from "react";
import { t, ngettext, msgid } from "ttag";
import { connect } from "react-redux";
import { ILocation } from "../../../models/location.interfaces";
import { TStateMapper } from "../../reducers.interfaces";
import { nearbyStoreSelector, preferredLocationSelector } from "../selectors";
import { ChatLink } from "../../chat/ChatLink";
import { trackSleepExpertEvent } from "../../../utils/analytics";
import urls from "../../../utils/urls";
import { formatPhoneNumber } from "../../../utils/format";

import styles from "./FooterWidgets.module.scss";
import classNames from "classnames";

interface IOwnProps {}

interface IReduxProps {
    numStores: number;
    location: ILocation | null;
}

type IProps = IReduxProps & IOwnProps;

interface IState {}

export const ContactSupportWidget = (props: { phone: string }) => {
    return (
        <div className={styles.contactSupport}>
            <h4 className={styles.heading}>
                <ChatLink
                    chatOnlineText={t`Talk to a Sleep Expert`}
                    chatOfflineText={t`Talk to a Sleep Expert`}
                    chatOfflineLink={urls.pageURL("contact-link")}
                />
            </h4>
            <span className={styles.copy}>
                <ChatLink chatOfflineLink={urls.pageURL("contact-link")} />
                <span> or call </span>
                <a
                    className={styles.link}
                    href={`tel:${props.phone}`}
                    onClick={() => trackSleepExpertEvent("call")}
                >
                    {formatPhoneNumber(props.phone)}
                </a>
            </span>
        </div>
    );
};

export const NearbyStoresWidgetComponent = (props: { text: string }) => {
    return (
        <div className={styles.nearbyStores}>
            <h4 className={styles.heading}>
                <a
                    title={t`Find a Retailer`}
                    href="/find-a-retailer"
                    className="footer-widget__header"
                >
                    {t`Feel it for yourself`}
                </a>
            </h4>
            <span className={styles.copy}>
                <span>{props.text}</span>{" "}
                <a
                    className={styles.link}
                    title={t`Find a Retailer`}
                    href="/find-a-retailer"
                >
                    {t`See List`}
                </a>
            </span>
        </div>
    );
};

class NearbyStoresWidgetContainer extends React.Component<IProps, IState> {
    private getAdText() {
        if (this.props.location) {
            return ngettext(
                msgid`${this.props.numStores} store near ${this.props.location.formatted_address}`,
                `${this.props.numStores} stores near ${this.props.location.formatted_address}`,
                this.props.numStores,
            );
        }
        return ngettext(
            msgid`${this.props.numStores} store near you`,
            `${this.props.numStores} stores near you`,
            this.props.numStores,
        );
    }

    render() {
        return <NearbyStoresWidgetComponent text={this.getAdText()} />;
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    const props: IProps = {
        numStores: nearbyStoreSelector(state).length,
        location: preferredLocationSelector(state),
        ...ownProps,
    };
    return props;
};

export const NearbyStoresWidget = connect(mapStateToProps)(
    NearbyStoresWidgetContainer,
);

export const FooterWidgets = (props: {
    phoneNumber: string;
    showContactSupportWidget: boolean;
    showNearbyStoresWidget: boolean;
}) => {
    const firstWidgetClasses = classNames({
        [styles.firstWidget]:
            props.showContactSupportWidget && props.showNearbyStoresWidget,
        [styles.widgetWrapper]: props.showContactSupportWidget,
    });
    if (!props.showContactSupportWidget && !props.showNearbyStoresWidget) {
        return null;
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {props.showContactSupportWidget && (
                    <div className={firstWidgetClasses}>
                        <ContactSupportWidget phone={props.phoneNumber} />
                    </div>
                )}
                {props.showNearbyStoresWidget && (
                    <div className={styles.widgetWrapper}>
                        <NearbyStoresWidget />
                    </div>
                )}
            </div>
        </div>
    );
};
