import { t } from "ttag";

export const consentMustBeChecked = (checked: string | boolean) => {
    if (!checked) {
        return t`Please indicate your consent by checking the box above.`;
    }
    return null;
};

export const termsMustBeChecked = (checked: string | boolean) => {
    if (!checked) {
        return t`Please accept the terms of the card agreement by checking the box above.`;
    }
    return null;
};
