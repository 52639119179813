import React from "react";
import { Image } from "../../../common/Image";
import { ITabFeatureAttributeBlock_AttributeTab } from "../models.interfaces";
import { tabFeatureAttributeBlock } from "../elementIDs";
import styles from "./TabFeatureAttributeBlockAttributeTabButton.module.scss";

interface IProps {
    idx: number;
    tab: ITabFeatureAttributeBlock_AttributeTab;
    onClick: () => void;
    isSelected: boolean;
}

interface IState {}

export class TabFeatureAttributeBlockAttributeTabButton extends React.Component<
    IProps,
    IState
> {
    render() {
        return (
            <button
                className={styles.root}
                id={tabFeatureAttributeBlock.attributeTabButton(
                    this.props.tab,
                    this.props.idx,
                )}
                type="button"
                role="tab"
                aria-controls={tabFeatureAttributeBlock.attributeTabContent(
                    this.props.tab,
                    this.props.idx,
                )}
                tabIndex={this.props.isSelected ? 0 : -1}
                aria-selected={this.props.isSelected}
                onClick={this.props.onClick}
            >
                <span className={styles.label}>
                    {this.props.tab.attribute_value}
                </span>
                <div className={styles.iconCircle}>
                    <div className={styles.iconBg}>
                        <div className={styles.icon}>
                            <Image
                                alt={this.props.tab.icon.title}
                                src={this.props.tab.icon.url}
                            />
                        </div>
                    </div>
                </div>
            </button>
        );
    }
}
