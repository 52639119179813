import React from "react";
import { Step } from "../constants";
import styles from "./MattressMatchNav.module.scss";
import { NavButton } from "../components/NavButton";

export const MattressMatchNav = () => {
    return (
        <nav className={styles.navContainer}>
            <span className={styles.gradient}></span>
            <div className={styles.nav}>
                {Object.values(Step).map((step) => (
                    <NavButton key={step} step={step} styles={styles.button} />
                ))}
            </div>
        </nav>
    );
};
