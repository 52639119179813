import React from "react";
import { t } from "ttag";
import { IProduct } from "../../../models/catalogue.interfaces";
import { ConfiguratorFinancing } from "../../../common/ConfiguratorFinancing";
import { ConfiguratorProductPrice } from "../../../common/ConfiguratorProductPrice";
import { Image } from "../../../common/Image";
import { Link } from "../../../common/Link";
import { DiscountDisplayMode } from "../../configurator/constants";
import { ValueProps } from "../components/ValueProps";
import styles from "./Result.module.scss";
import RichText from "../../../common/RichText";
import { addProductToBasket } from "../../../apps/configurator/actions";
import { trackAddToBasketEvent } from "../../../utils/analytics";
import urls from "../../../utils/urls";
import bestMatchIcon from "../../../../img/icons/mattress-selector/best-match.svg";
import secondPickIcon from "../../../../img/icons/mattress-selector/second-pick.svg";
import SVG from "react-inlinesvg";

interface IOwnProps {
    product: IProduct;
    productImg: string;
    productCopy: string;
    isBestMatch: boolean;
}

interface IProps extends IOwnProps {}

export const Result = (props: IProps) => {
    const onAddToBasket = async (
        event: React.MouseEvent<HTMLElement>,
        product: IProduct,
    ) => {
        event.preventDefault();
        try {
            await addProductToBasket(product.url);
            trackAddToBasketEvent(product);
            urls.navigateTo("basket-summary");
        } catch (e) {
            return;
        }
    };

    const attrs = props.product?.attributes;

    return (
        <div className={styles.result}>
            <div className={styles.headerContainer}>
                <SVG src={props.isBestMatch ? bestMatchIcon : secondPickIcon} />
                <div className={styles.header}>
                    <h2 className={styles.productTitle}>
                        {props.product.parent?.title}
                    </h2>
                    <p className={styles.productFeel}>
                        {attrs.option_feel?.value}
                    </p>
                </div>
            </div>
            <div className={styles.productContainer}>
                <div className={styles.productInfo}>
                    <div className={styles.imageContainer}>
                        <Image
                            data-src={props.productImg}
                            className={styles.productImage}
                            alt=""
                            lazy={true}
                        />
                    </div>
                    <div className={styles.priceWrapper}>
                        <div>
                            <ConfiguratorProductPrice
                                price={props.product?.price}
                                strikeThroughMSRP={false}
                                discountDisplayMode={
                                    DiscountDisplayMode.SHOW_ABSOLUTE
                                }
                                actualPriceStyle={"match"}
                            ></ConfiguratorProductPrice>
                            <span className={styles.sizeDisplay}>
                                {props.product.attributes.option_size?.value}
                            </span>
                        </div>
                        <ConfiguratorFinancing
                            price={props.product?.price}
                            variant={props.product}
                        ></ConfiguratorFinancing>
                    </div>
                    <Link
                        className={styles.detailsButton}
                        target="_top"
                        href={props.product.link}
                    >
                        {t`Product Details`}
                    </Link>
                    <button
                        className={styles.addButton}
                        onClick={(e) => {
                            onAddToBasket(e, props.product);
                        }}
                    >
                        {t`Add to Cart`}
                    </button>
                    {props.product.value_props && <ValueProps />}
                </div>
                <div className={styles.optionContainer}>
                    <h1>{t`Why you'll love it.`}</h1>
                    <RichText html={props.productCopy} />
                </div>
            </div>
        </div>
    );
};
