import React from "react";
import memoize from "memoize-one";
import { check } from "../../../models/utils";
import { urls } from "../../../utils/urls";
import { IModularConfiguratorOptionSet } from "../models.interfaces";
import { ModularConfiguratorOptionSet } from "../models";
import { Configurator, IOwnProps as IConfiguratorProps } from "./Configurator";

interface IProps {
    optionSetJSON: string;
    passthroughProps?: Partial<IConfiguratorProps>;
}

interface IState {}

export class ModularConfigurator extends React.PureComponent<IProps, IState> {
    private readonly parseOptionSetJSON = memoize(
        (optionSetJSON: string): IModularConfiguratorOptionSet => {
            const data = check(
                ModularConfiguratorOptionSet.decode(JSON.parse(optionSetJSON)),
            );
            return data;
        },
    );

    render() {
        const optionSet = this.parseOptionSetJSON(this.props.optionSetJSON);
        return (
            <Configurator
                optionSet={optionSet}
                financingLink={urls.pageURL("finance-link")}
                basketLink={urls.pageURL("basket-summary")}
                favoritesLink={urls.pageURL("basket-summary")}
                configureGiftsLink={urls.pageURL("configure-gifts")}
                {...this.props.passthroughProps}
            />
        );
    }
}
