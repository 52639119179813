// Import polyfills and setup error reporting system
import "./src/_bootstrap";
import { Provider, getServerData, getTemplate } from "@reactivated";
import { initPage } from "@thelabnyc/thelabui/src/index";

// Remove no-js class from HTML tag
const htmlElem = document.querySelector("html");
if (htmlElem) {
    htmlElem.classList.remove("no-js");
}

// Load CSS
import "./src/styles/main.scss";

// Set tsi-common-react configuration
import "./src/localization";

// This is the main entry point for new-style thelab-ui / reactivated
// components (which support SSR)
initPage({
    Provider,
    getServerData,
    getTemplate,
}).catch((e) => {
    console.error(e);
});

// Load legacy site components
import "./src/chunks/sentry";
import "./src/chunks/widgets";
import "./src/chunks/misc";
import "./src/chunks/modals";

import "./src/Checkout/index";
import "./src/ProductCompare/index";
import "./src/ProductDetail/index";
import "./src/ProductGrid/index";
import "./src/ProductList/index";
import "./src/RetailLocator/index";
import "./src/Reviews/index";
import "./src/Offers/index";
import "./src/TempurLoveGrid/index";
import "./src/UGCHomepage/index";
import "./src/Common/index";
import "./src/Orders/index";
import "./src/MattressMatch/index";

import "./src/chunks/analytics";
import "./src/chunks/chat";
