import React from "react";
import { connect } from "react-redux";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "tsi-common-react/src/models/location.interfaces";
import { TDispatchMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { preferredLocationSelector } from "tsi-common-react/src/apps/common/selectors";
import { IReduxState } from "../../reducers.interfaces";
import { ICMSFlagshipStorePage } from "../models.interfaces";
import { getAllFlagshipStorePages } from "../loaders";
import { Dispatchers } from "../dispatchers";
import { flagshipStoreSelector } from "../selectors";
import { FlagshipNearestStores as FlagshipNearestStoresComponent } from "../components/FlagshipNearestStores";

interface IReduxProps {
    stores: IRetailStoreWithDistance[];
    storePages: ICMSFlagshipStorePage[];
    preferredLocation: ILocation | null;
}

interface IOwnProps {
    maxTiles: number;
    includeLocationInfo: boolean;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

type IProps = IReduxProps & IOwnProps & IDispatchProps;

interface IState {}

class FlagshipNearestStoresContainer extends React.Component<IProps, IState> {
    componentDidMount() {
        this.loadStores();
    }

    private async loadStores() {
        const storePages = await getAllFlagshipStorePages();
        this.props.dispatchers.addFlagshipStorePages(storePages);
    }

    render() {
        return (
            <FlagshipNearestStoresComponent
                stores={this.props.stores}
                storePages={this.props.storePages}
                maxTiles={this.props.maxTiles}
                preferredLocation={this.props.preferredLocation}
                includeLocationInfo={this.props.includeLocationInfo}
            />
        );
    }
}

const mapStateToProps = (
    state: IReduxState,
    ownProps: IOwnProps,
): IReduxProps & IOwnProps => {
    return {
        ...ownProps,
        preferredLocation: preferredLocationSelector(state),
        stores: flagshipStoreSelector(state),
        storePages: Object.values(state.retail.flagshipStorePages),
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const FlagshipNearestStores = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FlagshipNearestStoresContainer);
