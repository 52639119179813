import React from "react";
import Modal from "react-modal";
import { ServiceFooterValuePropsModalContent } from "./ServiceFooterValuePropsModalContent";
import { focusElement } from "../../../utils/keyboardFocus";
import { IServiceFooterValueProps } from "../../../models/value-props.interfaces";

interface IProps {
    isOpen: boolean;
    onClose: (
        event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => void;
    content: IServiceFooterValueProps;
}

interface IState {
    contentHeight: number;
}

export class ServiceFooterValuePropsModal extends React.Component<
    IProps,
    IState
> {
    private contentElem: HTMLDivElement | undefined | null;
    private heightCalcDelayTimer: number | undefined = undefined;

    public state: IState = {
        contentHeight: 500,
    };

    private calcBestPlan() {
        const plansHTML = [].slice.call(
            document.querySelectorAll(".financing-plan-table__plan"),
        );
        return plansHTML[plansHTML.length - 1];
    }

    render() {
        const bestPlan: HTMLSpanElement = this.calcBestPlan();
        let planAPR = 0;
        let planLength = 0;
        if (bestPlan) {
            planAPR = parseInt(bestPlan.dataset.apr || "", 10);
            planLength = parseInt(bestPlan.dataset.length || "", 10);
        }

        let modalWidth = "";
        let modalPadding = "";
        if (window.innerWidth < 500) {
            modalWidth = "380px";
            modalPadding = "40px 15px 15px 15px";
        } else if (window.innerWidth < 768) {
            modalWidth = "85%";
            modalPadding = "50px 20px 20px 20px";
        } else {
            modalWidth = "66.666%";
            modalPadding = "30px";
        }

        console.log(`Content Height ${this.state.contentHeight}`);

        const modalStyleProps = {
            content: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
                width: modalWidth,
                height: `${this.state.contentHeight + 55}px`,
                padding: modalPadding,
                overflow: "hidden",
                boxShadow: "none",
            },
        };
        return (
            <Modal
                onAfterOpen={() => {
                    // Timeout currently used to mitigate miscalculation of height on first load
                    // TODO: Figure out why height is correct on second load and remove timoe
                    this.heightCalcDelayTimer = window.setTimeout(() => {
                        if (this.contentElem) {
                            this.setState({
                                contentHeight:
                                    this.contentElem.getBoundingClientRect()
                                        .height,
                            });
                        }
                    }, 100);
                    focusElement(".service-footer-modal__close");
                }}
                contentLabel="Buy Direct For Exclusive Benefits"
                aria={{ modal: true }}
                style={modalStyleProps}
                isOpen={this.props.isOpen}
                onRequestClose={(event) => {
                    window.clearTimeout(this.heightCalcDelayTimer);
                    this.props.onClose(event);
                }}
                role="dialog"
                overlayClassName={
                    "service-footer-container__react-modal-overlay"
                }
                className={"service-footer-container__react-modal-content"}
            >
                <div
                    ref={(ref) => {
                        this.contentElem = ref;
                    }}
                >
                    <ServiceFooterValuePropsModalContent
                        onCloseModal={this.props.onClose}
                        apr={planAPR}
                        length={planLength}
                        content={this.props.content}
                    />
                </div>
            </Modal>
        );
    }
}
