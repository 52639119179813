import React from "react";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { IStoreSearchFilters } from "tsi-common-react/src/apps/retail/models.interfaces";
import { unique } from "tsi-common-react/src/utils/functional";
import { IFilterChangeHandler } from "../models.interfaces";
import { LocatorFilterPopover } from "./LocatorFilters_Popover";

interface IProps {
    filterValues: IStoreSearchFilters;
    stores: IRetailStoreWithDistance[];
    onChange: IFilterChangeHandler;
}

interface IState {
    filterValueInRedux: string[];
    selectedStoreNames: Set<string>;
}

export class RetailerFilter extends React.Component<IProps, IState> {
    state: IState = {
        filterValueInRedux: this.props.filterValues.retailerName,
        selectedStoreNames: new Set(),
    };

    private readonly filterElem = React.createRef<LocatorFilterPopover>();

    static getDerivedStateFromProps(
        props: IProps,
        state: IState,
    ): IState | null {
        if (props.filterValues.retailerName !== state.filterValueInRedux) {
            return {
                filterValueInRedux: props.filterValues.retailerName,
                selectedStoreNames: new Set(props.filterValues.retailerName),
            };
        }
        return null;
    }

    private readonly onInputChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        const checked = event.currentTarget.checked;
        const value = event.currentTarget.value;
        this.setState((s) => {
            const selectedStoreNames = new Set(s.selectedStoreNames);
            if (checked) {
                selectedStoreNames.add(value);
            } else {
                selectedStoreNames.delete(value);
            }
            return {
                selectedStoreNames: selectedStoreNames,
            };
        });
    };

    private readonly onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.onChange({
            retailerName: Array.from(this.state.selectedStoreNames),
        });
        if (this.filterElem.current) {
            this.filterElem.current.onRequestClose();
        }
    };

    private readonly onReset = () => {
        // Deselect everything
        this.props.onChange({
            retailerName: [],
        });
        if (this.filterElem.current) {
            this.filterElem.current.onRequestClose();
        }
    };

    private readonly onCancel = () => {
        // Close the modal
        if (this.filterElem.current) {
            this.filterElem.current.onRequestClose();
        }
    };

    private readonly onAfterClose = () => {
        // Revert filter back to the state in Redux
        this.setState((s) => {
            return {
                ...s,
                selectedStoreNames: new Set(s.filterValueInRedux),
            };
        });
    };

    private getStoreNames() {
        return this.props.stores
            .map((s) => s.name)
            .filter(unique)
            .sort();
    }

    private buildInputs() {
        const storeNames = this.getStoreNames();
        const inputs = storeNames.map((name) => {
            return (
                <label key={name} className="filters__list-item">
                    <input
                        type="checkbox"
                        name="retailer"
                        value={name}
                        onChange={this.onInputChange}
                        checked={this.state.selectedStoreNames.has(name)}
                    />{" "}
                    {name}
                </label>
            );
        });
        return inputs;
    }

    render() {
        return (
            <LocatorFilterPopover
                ref={this.filterElem}
                id="retailers"
                labelText="retailers"
                onAfterClose={this.onAfterClose}
            >
                <form
                    className="filters__dropdown filters__dropdown--retailers"
                    onSubmit={this.onSubmit}
                >
                    <fieldset>
                        <legend className="filters__instruct">
                            Narrow your search results by selecting the
                            retailers that interest you.
                        </legend>
                        <h3 className="filters__header">
                            Retailers{" "}
                            <span className="filters__header--right">A-Z</span>
                        </h3>
                        <div className="filters__list filters__list--scrollable bh-sl-filters">
                            {this.buildInputs()}
                        </div>
                        <div className="filters__actions filters__actions--full-width">
                            <label
                                htmlFor="retailer-apply-button"
                                style={{ display: "none" }}
                            >
                                Apply Selections
                            </label>
                            <input
                                id="retailer-apply-button"
                                className="filters__button button"
                                type="submit"
                                value="Apply Selections"
                            />
                            <p className="filters__links">
                                <a
                                    className="filters__link close-reset"
                                    onClick={this.onReset}
                                >
                                    Reset
                                </a>
                                <a
                                    className="filters__link close-tooltip"
                                    onClick={this.onCancel}
                                >
                                    Cancel
                                </a>
                            </p>
                        </div>
                    </fieldset>
                </form>
            </LocatorFilterPopover>
        );
    }
}
