import React from "react";
import { connect } from "react-redux";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { preferredLocationSelector } from "tsi-common-react/src/apps/common/selectors";
import { getStoreByExternalID } from "../loaders";
import { defaultLocation } from "../defaults";
import { LocatorSendSingleStore as LocatorSendSingleStoreComponent } from "../components/LocatorSendSingleStore";

interface IReduxProps {
    location: ILocation | null;
}

interface IOwnProps {
    storeID: ISyncStoreID;
}

type IProps = IReduxProps & IOwnProps;

interface IState {
    store: IRetailStoreWithDistance | null;
}

class LocatorSendSingleStoreContainer extends React.Component<IProps, IState> {
    state: IState = {
        store: null,
    };

    componentDidMount() {
        this.loadStore(this.props.storeID);
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.storeID !== this.props.storeID) {
            this.loadStore(this.props.storeID);
        }
    }

    private async loadStore(storeID: ISyncStoreID) {
        const location = this.props.location || defaultLocation;
        const stores = await getStoreByExternalID(location, storeID);
        this.setState({
            store: stores[0] || null,
        });
    }

    render() {
        if (!this.state.store) {
            return null;
        }
        return <LocatorSendSingleStoreComponent store={this.state.store} />;
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        location: preferredLocationSelector(state),
        ...ownProps,
    };
};

export const LocatorSendSingleStore = connect(mapStateToProps)(
    LocatorSendSingleStoreContainer,
);
