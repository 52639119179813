import React, { forwardRef } from "react";
import { t } from "ttag";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { strings } from "../../../localization";
import {
    BackendType,
    FinancingApplicationFields,
    FinancingApplicationErrorSet,
} from "../../../models/financing";
import { FormStepType } from "../../../models/forms";
import { objectKeys } from "../../../utils/functional";
import { Form } from "../../../forms/Form";
import { NonFieldErrors } from "../../../forms/NonFieldErrors";
import { AutoFieldsMultistep } from "../../../forms/AutoFieldsMultistep";
import { FormSubmit } from "../../../forms/FormSubmit";
import { Tooltip } from "./Tooltip";
import { FinancingAppType } from "../constants";
import { FullApplicationValues, IndividualSteps, JointSteps } from "../models";
import { getFields } from "./fields";

import iconPersonIndividual from "../../../../img/icons/person-individual.svg";
import iconPersonJoint from "../../../../img/icons/person-joint.svg";
import fortivaLogo from "../../../../img/finance/fortiva-logo.svg";

import styles from "./ApplyFormSteps.module.scss";

interface Props {
    formSteps: IndividualSteps | JointSteps;
    currStepIndex: number;
    disabledSteps: number[];
    isJoint: boolean;
    isLoading: boolean;
    backendType: BackendType;

    values: FullApplicationValues;

    showErrorMessages: boolean;
    serverErrors: FinancingApplicationErrorSet;
    clientErrors: FinancingApplicationErrorSet;

    onAppTypeChange: (value: FinancingAppType) => void;

    onNextStep: (
        event:
            | React.FormEvent<HTMLButtonElement>
            | React.FormEvent<HTMLFormElement>,
    ) => Promise<void>;

    onPrevStep: () => void;

    onChange: (name: FinancingApplicationFields, value: string) => void;

    onValidStateChange: (
        fieldName: FinancingApplicationFields,
        errorMessages: string[],
    ) => void;

    onSummaryEdit: (step: number) => void;
}

const getCombinedErrors = (
    serverErrors: FinancingApplicationErrorSet,
    clientErrors: FinancingApplicationErrorSet,
): FinancingApplicationErrorSet => {
    const clientFieldNames = objectKeys(clientErrors);
    const serverFieldNames = objectKeys(serverErrors);
    const fieldNames = clientFieldNames.concat(serverFieldNames);
    const errors: FinancingApplicationErrorSet = {};
    fieldNames.forEach((name) => {
        const _clientErrors = [clientErrors[name] || []].flat();
        const _serverErrors = [serverErrors[name] || []].flat();
        const errs = _clientErrors.concat(_serverErrors);
        if (name === "code" || name === "detail") {
            errors[name] = errs[0];
        } else {
            errors[name] = errs;
        }
    });
    return errors;
};

export const ApplyFormSteps = forwardRef<Form, Props>((props, ref) => {
    const closedSteps = props.formSteps.map((step, index) => {
        if (
            index > props.currStepIndex &&
            index <= props.formSteps.length - 1
        ) {
            switch (step.stepType) {
                case FormStepType.FieldSet:
                    return (
                        <div className={styles.closedStep} key={index}>
                            {step.groupTitle}
                        </div>
                    );

                case FormStepType.OtherJSX:
                    return (
                        <div className={styles.closedStep} key={index}>
                            Terms and Details
                        </div>
                    );
            }
        }
        return <span key={index}></span>;
    });

    const isOnFirstStep = props.currStepIndex === 0;
    const isOnLastStep = props.currStepIndex === props.formSteps.length - 1;

    const individualFormControlClasses = classNames({
        [styles.control]: true,
        [styles.activeControl]: !props.isJoint,
    });
    const jointFormControlClasses = classNames({
        [styles.control]: true,
        [styles.activeControl]: props.isJoint,
    });
    const modalButtonBackClasses = classNames({
        "button": true,
        "button--inverse": true,
        [`al-financing-modal__back-button--${props.currStepIndex + 1}`]: true,
        "al-financing-modal__back-button--final": isOnLastStep,
    });

    let introCopy: JSX.Element | null = null;
    if (isOnFirstStep && props.backendType === BackendType.WELLSFARGO) {
        introCopy = (
            <div className={styles.introCopy}>
                <h2 className={styles.introHeading}>
                    {strings.get("FINANCING_APP_INTRO_HEAD")}
                </h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: strings.get("FINANCING_APP_INTRO_BODY") || "",
                    }}
                ></div>
            </div>
        );
    }

    const backButton = (
        <button
            className={modalButtonBackClasses}
            type="button"
            onClick={props.onPrevStep}
        >
            Back
        </button>
    );

    const continueButtonClasses = classNames({
        button: true,
        [`al-financing-modal__continue-button--step-${
            props.currStepIndex + 1
        }`]: true,
    });
    const nextButton = (
        <button
            className={continueButtonClasses}
            type="button"
            onClick={props.onNextStep}
        >
            Continue
        </button>
    );

    const submitButton = (
        <FormSubmit
            className="button al-financing-modal_submit-application-button"
            value="Submit Application"
            disabled={props.isLoading}
        />
    );

    const combinedErrors = getCombinedErrors(
        props.serverErrors,
        props.clientErrors,
    );

    return (
        <Form
            onSubmit={props.onNextStep}
            ref={ref}
            className={styles.formOverrides}
        >
            {props.backendType === BackendType.WELLSFARGO && (
                <div className={styles.controlsWrapper}>
                    <button
                        type="button"
                        className={individualFormControlClasses}
                        onClick={() => {
                            props.onAppTypeChange(FinancingAppType.INDIVIDUAL);
                        }}
                        aria-current={!props.isJoint}
                    >
                        <SVG
                            className={styles.icon}
                            src={iconPersonIndividual}
                            aria-hidden={true}
                        />
                        <Tooltip
                            options={t`Individual`}
                            copy={t`An account owned by one person who is responsible for the account.`}
                        />
                    </button>
                    <button
                        type="button"
                        className={jointFormControlClasses}
                        onClick={() => {
                            props.onAppTypeChange(FinancingAppType.JOINT);
                        }}
                        aria-current={props.isJoint}
                    >
                        <SVG
                            className={styles.jointIcon}
                            src={iconPersonJoint}
                            aria-hidden={true}
                        />
                        <Tooltip
                            options={t`Joint`}
                            copy={t`An account owned by two or more people who are equally responsible for the account.`}
                        />
                    </button>
                </div>
            )}
            {props.backendType === BackendType.FORTIVA && (
                <div className={styles.fortivaHeaderWrapper}>
                    <SVG src={fortivaLogo} aria-hidden={true} />
                    <h2 className={styles.fortivaHeading}>
                        Fortiva Retail Credit Application
                    </h2>
                </div>
            )}
            <div className={styles.nonFieldErrors}>
                <NonFieldErrors
                    errors={
                        props.serverErrors.detail
                            ? [props.serverErrors.detail]
                            : []
                    }
                    showErrorMessages={true}
                />
                <NonFieldErrors
                    errors={props.serverErrors.non_field_errors}
                    showErrorMessages={true}
                />
            </div>
            {introCopy}
            <AutoFieldsMultistep
                className={styles.wrapper}
                steps={props.formSteps}
                currStepIndex={props.currStepIndex}
                disabledSteps={props.disabledSteps}
                fields={getFields(props.values, props.backendType)}
                values={props.values}
                errors={combinedErrors}
                disabled={props.isLoading}
                onChange={props.onChange}
                onValidStateChange={props.onValidStateChange}
                showErrorMessages={props.showErrorMessages}
                onSummaryEdit={props.onSummaryEdit}
            />
            <div className={styles.buttonContainer}>
                {isOnLastStep ? submitButton : nextButton}
                {!isOnFirstStep && backButton}
            </div>
            <div>{closedSteps}</div>
        </Form>
    );
});
