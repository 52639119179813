import React from "react";
import { connect } from "react-redux";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import {
    TStateMapper,
    TDispatchMapper,
} from "tsi-common-react/src/apps/reducers.interfaces";
import { preferredLocationSelector } from "tsi-common-react/src/apps/common/selectors";
import {
    filteredStoreSelector,
    focusedStoreIDSelector,
} from "tsi-common-react/src/apps/retail/selectors";
import { IStoreSearchFilters } from "tsi-common-react/src/apps/retail/models.interfaces";
import { Dispatchers } from "../dispatchers";
import { LocatorNearestStores as LocatorNearestStoresComponent } from "../components/LocatorNearestStores";

interface IOwnProps {}

interface IReduxProps {
    stores: IRetailStoreWithDistance[];
    location: ILocation | null;
    filters: IStoreSearchFilters;
    focusedStoreID: ISyncStoreID | null;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

type IProps = IReduxProps & IOwnProps & IDispatchProps;

interface IState {}

class LocatorNearestStoresContainer extends React.Component<IProps, IState> {
    render() {
        return (
            <LocatorNearestStoresComponent
                stores={this.props.stores}
                focusedStoreID={this.props.focusedStoreID}
                onSelectStore={this.props.dispatchers.setFocusedStore}
                showMore={this.props.filters.limit ? true : false}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        stores: filteredStoreSelector(state),
        focusedStoreID: focusedStoreIDSelector(state),
        location: preferredLocationSelector(state),
        filters: state.retail.storeFilters,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const LocatorNearestStores = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LocatorNearestStoresContainer);
