import React from "react";
import classNames from "classnames";
import { ICompatibilityAccordionBlock, CompatibilityPanel } from "../models";
import { Image } from "../../common/Image";
import { getViewportBreakpoint } from "../../utils/detectMobile";
import { BreakPoint } from "../../models/screen.interfaces";
import styles from "./CompatibilityAccordion.module.scss";

interface IProps {
    cms: ICompatibilityAccordionBlock;
    contentPanels: CompatibilityPanel[];
}

interface IState {
    useIcon: boolean | null;
    activePanel: CompatibilityPanel | null;
}

export class CompatibilityAccordion extends React.Component<IProps, IState> {
    state: IState = {
        useIcon: false,
        activePanel: null,
    };

    componentDidMount() {
        this.setState({
            useIcon: this.props.cms?.use_icon,
            activePanel: this.props.contentPanels[0],
        });
    }

    private clickPanel(panel: CompatibilityPanel) {
        this.setState({ activePanel: panel });
    }

    private getIcon(compatibility: boolean) {
        if (!this.state.useIcon) {
            return;
        }
        const imgName = compatibility ? "icon_comp" : "icon_incomp";
        const altText = compatibility ? "compatible" : "incompatibile";

        return (
            <Image
                src={this.props.cms[`${imgName}`]?.url}
                alt={`${altText} icon`}
            />
        );
    }
    private buildPanel(panel: CompatibilityPanel) {
        const rowClassName = classNames({
            [styles.row]: true,
            [styles.rowIcon]: this.state.useIcon,
        });
        const triggerClassName = classNames({
            [styles.accordionPanelTrigger]: true,
            [styles.accordionPanelTriggerIsActive]:
                this.state.activePanel === panel,
        });

        const targetClassName = classNames({
            [styles.accordionPanelTarget]: true,
            [styles.compatibilityAccordionImage]:
                getViewportBreakpoint() < BreakPoint.LARGE,
            [styles.accordionPanelTargetIsActive]:
                this.state.activePanel === panel,
        });
        return (
            <div className={rowClassName} key={panel.value.title}>
                <button
                    className={triggerClassName}
                    onClick={() => {
                        if (this.state.activePanel !== panel) {
                            this.clickPanel(panel);
                        }
                    }}
                >
                    {this.getIcon(panel.value.choice)} {panel.value.title}
                </button>
                <div className={targetClassName}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: panel.value.description || "",
                        }}
                    ></div>

                    {getViewportBreakpoint() < BreakPoint.MEDIUM && (
                        <Image
                            src={panel.value.image?.url}
                            alt={panel.value.title}
                            lazy={false}
                            className=""
                        />
                    )}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className={styles.compatibilityAccordionContent}>
                <div
                    className={styles.compatibilityAccordionHeader}
                    dangerouslySetInnerHTML={{
                        __html: this.props.cms.compatibility_header,
                    }}
                ></div>
                {getViewportBreakpoint() >= BreakPoint.MEDIUM && (
                    <div
                        className={`${styles.compatibilityAccordionImage} ${styles.accordionPanelTarget} ${styles.accordionPanelTargetIsActive}`}
                    >
                        {this.state.activePanel && (
                            <Image
                                lazy={false}
                                className=""
                                src={this.state.activePanel?.value.image.url}
                                alt={this.state.activePanel?.value.title}
                            />
                        )}
                    </div>
                )}
                <div className={styles.compatibilityAccordionPanels}>
                    {this.props.contentPanels.map(
                        (panel: CompatibilityPanel) => {
                            return this.buildPanel(panel);
                        },
                    )}
                </div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: this.props.cms.compatibility_footer || "",
                    }}
                ></div>
            </div>
        );
    }
}
