import React from "react";
import { t } from "ttag";
import Modal from "react-modal";
import { isMobile } from "../../../utils/detectMobile";
import { getFooterSettings } from "../../../utils/settings";
import { FinancingModalTrigger } from "../../../apps/financing/FinancingModalTrigger";
import { ContactUsModal } from "./ContactUsModal";
import styles from "./ServiceFooter.module.scss";
import { ServiceFooterValuePropsModal } from "./ServiceFooterValuePropsModal";
import { EmailModal } from "./EmailModal";
import { IEmailModalSnippet } from "./EmailCaptureContent";
import { IServiceFooterValueProps } from "../../../models/value-props.interfaces";
import Flickity from "flickity-imagesloaded";
import classNames from "classnames";
import RichText from "../../../common/RichText";

Modal.defaultStyles = {
    overlay: {},
    content: {},
};

export interface IServiceFooterSnippetProps {
    title: string;
    type: string;
    icon?: string;
    content: IServiceFooterValueProps | IEmailModalSnippet | null;
    trigger: string;
    trigger_mobile: string;
}

interface IProps {
    interval: number | null;
    loops: number | null;
    snippets: IServiceFooterSnippetProps[];
}

interface IState {
    modalContactIsOpen: boolean;
    modalPropsIsOpen: boolean;
    modalEmailIsOpen: boolean;
}

export class ServiceFooter extends React.Component<IProps, IState> {
    private carouselFlickity: Flickity | null = null;
    private snippetsElem: HTMLDivElement | null = null;
    private carouselTimer: number | null = null;

    public state: IState = {
        modalContactIsOpen: false,
        modalPropsIsOpen: false,
        modalEmailIsOpen: false,
    };

    componentDidMount() {
        if (!this.snippetsElem) {
            return;
        }

        this.initCarousel(this.snippetsElem);
    }

    componentWillUnmount() {
        if (this.carouselFlickity) {
            if (this.carouselTimer) {
                window.clearTimeout(this.carouselTimer);
            }

            this.carouselFlickity.destroy();
        }
    }

    private initCarousel(carousel: HTMLDivElement) {
        if (carousel.childNodes.length > 1) {
            this.carouselFlickity = new Flickity(carousel, {
                cellAlign: "center",
                pageDots: false,
                prevNextButtons: true,
                imagesLoaded: true,
                arrowShape: {
                    x0: 20,
                    x1: 60,
                    y1: 50,
                    x2: 60,
                    y2: 30,
                    x3: 35,
                },
                autoPlay: this.props.interval ? this.props.interval : true,
                pauseAutoPlayOnHover: true,
                wrapAround: true,
            });

            if (this.props.loops) {
                const interval = this.props.interval
                    ? this.props.interval
                    : 3000;
                const slideNum = this.props.snippets.length;
                const duration = interval * slideNum * this.props.loops;

                this.carouselTimer = window.setInterval(() => {
                    this.carouselFlickity?.stopPlayer();
                }, duration);
            }

            carousel.addEventListener("mouseover", () => {
                this.carouselFlickity?.stopPlayer();
            });
            carousel.addEventListener("focus", () => {
                this.carouselFlickity?.stopPlayer();
            });
        }
    }

    private readonly onOpenModalContact = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        if (event) {
            event.preventDefault();
        }

        this.setState({
            modalContactIsOpen: true,
        });
    };

    private readonly onOpenModalProps = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        if (event) {
            event.preventDefault();
        }

        this.setState({
            modalPropsIsOpen: true,
        });
    };

    private readonly onOpenModalEmail = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        if (event) {
            event.preventDefault();
        }

        this.setState({
            modalEmailIsOpen: true,
        });
    };

    private readonly onCloseModalContact = (
        event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
        if (event) {
            event.preventDefault();
        }

        this.setState({
            modalContactIsOpen: false,
        });
    };

    private readonly onCloseModalProps = (
        event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
        if (event) {
            event.preventDefault();
        }

        this.setState({
            modalPropsIsOpen: false,
        });
    };

    private readonly onCloseModalEmail = (
        event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
        if (event) {
            event.preventDefault();
        }

        this.setState({
            modalEmailIsOpen: false,
        });
    };

    private renderSnippetContent(
        trigger: IServiceFooterSnippetProps,
        icon?: string,
    ) {
        const content =
            isMobile.any() && trigger.trigger_mobile.length > 0
                ? trigger.trigger_mobile
                : trigger.trigger;

        return (
            <>
                {icon && <img alt="" src={icon} />}
                <RichText inline={true} html={content} />
            </>
        );
    }

    private renderSnippetButton(
        isCarousel: boolean,
        snippet: IServiceFooterSnippetProps,
        onClick?: React.MouseEventHandler<HTMLButtonElement>,
    ) {
        const classes = classNames({
            [styles.trigger]: true,
            [styles.carouselTrigger]: isCarousel,
        });

        return (
            <button className={classes} onClick={onClick ? onClick : undefined}>
                {this.renderSnippetContent(snippet, snippet.icon)}
            </button>
        );
    }

    private renderSnippets() {
        const isCarousel = this.props.snippets.length > 1;

        return this.props.snippets.map((snippet, i) => {
            switch (snippet.type) {
                case "default":
                    return (
                        <React.Fragment key={i}>
                            {this.renderSnippetButton(isCarousel, snippet)}
                        </React.Fragment>
                    );
                case "email":
                    return (
                        <React.Fragment key={i}>
                            {this.renderSnippetButton(
                                isCarousel,
                                snippet,
                                this.onOpenModalEmail,
                            )}
                            <EmailModal
                                isOpen={this.state.modalEmailIsOpen}
                                onClose={this.onCloseModalEmail}
                                content={snippet.content as IEmailModalSnippet}
                            />
                        </React.Fragment>
                    );
                case "prequal":
                    const classes = classNames({
                        [styles.financingModalTrigger]: true,
                        "al-service-footer-finance-block__pre-qualify": true,
                        [styles.carouselTrigger]: isCarousel,
                    });
                    return (
                        <FinancingModalTrigger
                            key={`Trigger-${i}`}
                            modalType="prequal-app"
                            className={classes}
                        >
                            {this.renderSnippetContent(snippet, snippet.icon)}
                        </FinancingModalTrigger>
                    );
                case "value_prop":
                    return (
                        <React.Fragment key={i}>
                            {this.renderSnippetButton(
                                isCarousel,
                                snippet,
                                this.onOpenModalProps,
                            )}
                            <ServiceFooterValuePropsModal
                                isOpen={this.state.modalPropsIsOpen}
                                onClose={this.onCloseModalProps}
                                content={
                                    snippet.content as IServiceFooterValueProps
                                }
                            />
                        </React.Fragment>
                    );
                default:
                    throw new Error(
                        `Unexpected service footer snippet type: ${snippet.type}`,
                    );
            }
        });
    }

    render() {
        const contactUs = !!getFooterSettings() && (
            <div
                className={`${styles.contactUs} service-footer__contact-us al-service-footer__buttons`}
            >
                <button onClick={this.onOpenModalContact}>
                    {t`Contact Us`}
                </button>
            </div>
        );

        return (
            <div className={`${styles.root} service-footer al-service-footer`}>
                <div className={`${styles.content} l-capped-width`}>
                    <div
                        className={styles.snippets}
                        ref={(ref) => {
                            this.snippetsElem = ref;
                        }}
                    >
                        {this.renderSnippets()}
                    </div>
                    {contactUs}
                </div>
                <ContactUsModal
                    isOpen={this.state.modalContactIsOpen}
                    onClose={this.onCloseModalContact}
                />
                <div className={styles.iphoneXbg}></div>
            </div>
        );
    }
}
