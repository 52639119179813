import React, { useEffect, useRef, useState } from "react";
import { FormStep, FormStepType } from "../../../models/forms";
import { Form } from "../../../forms/Form";
import { focusElement } from "../../../utils/keyboardFocus";
import { trackFormEvent } from "../../../utils/analytics";
import {
    FinancingApplicationFields,
    FinancingPreQualResponse,
    FinancingApplicationPrecursor,
} from "../../../models/financing";
import { useAppDispatch, useAppSelector } from "../../reducers";
import {
    updateFullAppFormState,
    submitFullApplication,
    fullAppNextStep,
    fullAppPrevStep,
} from "../reducers";
import {
    ModalFullFormState,
    TStepIndex,
    StepIndexFinancingFormNameMap,
} from "../models";
import { ApplyFormSteps } from "./ApplyFormSteps";
import { FinancingAppType, ModalStateType } from "../constants";
import { ClientErrors, AgreeFields } from "../models";
import { getFormSteps } from "./steps";

const getCurrentStepErrorCount = (
    currentStep: FormStep<FinancingApplicationFields>,
    clientErrors: ClientErrors,
): number => {
    // Tally up any errors we have
    if (currentStep.stepType === FormStepType.FieldSet) {
        const fieldsToValidate = currentStep.fields;
        return fieldsToValidate.reduce((memo, field) => {
            const fieldClientErrors = clientErrors[field] || [];
            return memo + fieldClientErrors.length;
        }, 0);
    }
    const isTermsStep = currentStep.stepType === FormStepType.OtherJSX;
    if (isTermsStep) {
        const fieldsToValidate = [
            "agree_disclosure",
            "agree_disclosure_joint",
            "esign",
            "esign_joint",
        ] as const satisfies AgreeFields[];
        return fieldsToValidate.reduce((memo, field) => {
            const fieldClientErrors = clientErrors[field] || [];
            return memo + fieldClientErrors.length;
        }, 0);
    }
    return 0;
};

const focusOnFirstError = (form: HTMLFormElement | null | undefined) => {
    // Check error fields and focus first message
    setTimeout(() => {
        if (!form) {
            return;
        }
        const fields = form.querySelectorAll<HTMLElement>(
            ".form__field.form__field--has-errors",
        );
        if (fields.length <= 0) {
            return;
        }
        const fieldInput = fields[0].querySelector<HTMLElement>(
            "input, select, textarea",
        );
        if (fieldInput) {
            focusElement(fieldInput);
            const top = fieldInput.offsetTop - window.innerHeight / 2;
            window.scrollTo(0, top);
        }
    }, 250);
};

const focusOnFirstField = (form: HTMLFormElement | null | undefined) => {
    setTimeout(() => {
        if (!form) {
            return;
        }
        const fields = form.querySelectorAll<HTMLElement>(
            ".form__field.form__field--required, .form__field.form__field--optional",
        );
        if (fields.length <= 0) {
            return;
        }
        const fieldInput = fields[0].querySelector<HTMLElement>(
            "input, select, textarea, button",
        );
        if (fieldInput) {
            focusElement(fieldInput);
        }
    }, 250);
};

const scrollToTop = () => {
    const checkoutStep = document.querySelector(
        ".checkout-step__payment-enabled-fields",
    );
    if (!checkoutStep) {
        return;
    }
    checkoutStep.scrollIntoView();
};

const getPrecursors = (
    preQualApproval: FinancingPreQualResponse | null,
): FinancingApplicationPrecursor[] => {
    if (!preQualApproval) {
        return [];
    }
    return [
        {
            backend_type: preQualApproval.backend_type,
            reservation_number: preQualApproval.offer_indicator,
            application_id: preQualApproval.response_id,
        },
    ];
};

export const FinancingFullApplyForm = (props: {
    modalState: ModalFullFormState;
}) => {
    const appForm = useRef<Form | null>(null);

    const dispatch = useAppDispatch();
    const applicationSource = useAppSelector(
        (state) => state.financing.applicationSource,
    );
    const preQualApproval = useAppSelector(
        (state) => state.financing.preQualApproval,
    );
    const basket = useAppSelector((state) => state.checkout.data.basket);

    const [clientErrors, setClientErrors] = useState<ClientErrors>({});
    const [showErrorMessages, setShowErrorMessages] = useState(false);

    // On first load, track a start event.
    const trackEvent = (
        event: Parameters<typeof trackFormEvent>[0],
        name: Parameters<typeof trackFormEvent>[1],
    ) => {
        if (appForm.current?.formElem) {
            trackFormEvent(event, name, appForm.current?.formElem, basket);
        }
    };
    useEffect(() => {
        const formName =
            StepIndexFinancingFormNameMap[props.modalState.currStepIndex];
        trackEvent("form_start", formName);
    }, []);

    const isSubmitting =
        props.modalState._tag === ModalStateType.APPLY_SUBMITTING;
    const serverErrors =
        props.modalState._tag === ModalStateType.APPLY_ERROR
            ? props.modalState.errors
            : {};

    const onAppTypeChange = (type: FinancingAppType) => {
        dispatch(
            updateFullAppFormState({
                appType: type,
                currStepIndex: 0,
            }),
        );
    };

    const onValidStateChange = (fieldName: string, errorMessages: string[]) => {
        setClientErrors((s) => ({
            ...s,
            [fieldName]: errorMessages,
        }));
    };

    const onAgree = (event: React.FormEvent<HTMLInputElement>) => {
        const name = event.currentTarget.name;
        if (!AgreeFields.is(name)) {
            return;
        }
        const isChecked = event.currentTarget.checked;
        dispatch(
            updateFullAppFormState({
                values: {
                    [name]: isChecked,
                },
            }),
        );
    };

    const formSteps = getFormSteps(
        props.modalState.appType,
        props.modalState.values,
        {
            backendType: props.modalState.backendType,
            offer: preQualApproval,
            onValidStateChange: onValidStateChange,
            showErrorMessages: showErrorMessages,
            clientErrors: clientErrors,
            onAgree: onAgree,
            onESign: onAgree,
        },
    );
    const currStepIndex = props.modalState.currStepIndex;
    const currentStep = formSteps[currStepIndex];
    const isOnLastStep = currStepIndex === formSteps.length - 1;

    const onNextStep = async (
        event:
            | React.FormEvent<HTMLButtonElement>
            | React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        event.preventDefault();

        // Stop the submit event from bubbling up any further. This is necessary because the Apply form, while not nested
        // inside any other forms according to the DOM hierarchy, *is* nested within a checkout form according to the React
        // component hierarchy. This causes checkout to try and submit when submitting the Apply form. Long term, we
        // should try and remove this nesting.
        // See also: https://github.com/erikras/redux-form/issues/3701
        event.stopPropagation();

        // Tally up any client-side errors we have
        const errorCount = currentStep
            ? getCurrentStepErrorCount(currentStep, clientErrors)
            : 0;

        // Don't go to next step if the current step is invalid. Just turn on error display.
        if (errorCount > 0) {
            setShowErrorMessages(true);
            focusOnFirstError(appForm.current?.formElem);
            return;
        }

        if (currentStep?.stepType === FormStepType.FieldSet) {
            trackEvent(
                "form_submit",
                StepIndexFinancingFormNameMap[currStepIndex],
            );
        }

        // If we're on the last step, submit the app to the server.
        if (isOnLastStep) {
            trackEvent(
                "form_submit",
                StepIndexFinancingFormNameMap[currStepIndex],
            );
            dispatch(
                submitFullApplication({
                    applicationSource: applicationSource,
                    backendType: props.modalState.backendType,
                    precursors: getPrecursors(preQualApproval),
                    appType: props.modalState.appType,
                    values: props.modalState.values,
                    currStepIndex: currStepIndex,
                    disabledSteps: props.modalState.disabledSteps,
                }),
            );
            return;
        }

        // Proceed to next step
        scrollToTop();
        setShowErrorMessages(false);
        dispatch(fullAppNextStep({ totalStepCount: formSteps.length }));
        focusOnFirstField(appForm.current?.formElem);
    };

    const onPrevStep = () => {
        scrollToTop();
        setShowErrorMessages(false);
        dispatch(fullAppPrevStep());
    };

    const onChange = (name: FinancingApplicationFields, value: string) => {
        dispatch(
            updateFullAppFormState({
                values: {
                    [name]: value,
                },
            }),
        );
    };

    const onSummaryEdit = (step: number) => {
        if (TStepIndex.is(step)) {
            dispatch(
                updateFullAppFormState({
                    currStepIndex: step,
                }),
            );
        }
    };

    return (
        <ApplyFormSteps
            ref={appForm}
            formSteps={formSteps}
            currStepIndex={currStepIndex}
            disabledSteps={props.modalState.disabledSteps}
            isJoint={props.modalState.appType === FinancingAppType.JOINT}
            isLoading={isSubmitting}
            backendType={props.modalState.backendType}
            values={props.modalState.values}
            showErrorMessages={showErrorMessages}
            serverErrors={serverErrors}
            clientErrors={clientErrors}
            onAppTypeChange={onAppTypeChange}
            onNextStep={onNextStep}
            onPrevStep={onPrevStep}
            onChange={onChange}
            onValidStateChange={onValidStateChange}
            onSummaryEdit={onSummaryEdit}
        />
    );
};
