import React from "react";
import { IProduct } from "../../../models/catalogue.interfaces";
import { Image } from "../../../common/Image";

interface IProps {
    rootProduct: IProduct | null;
    variant: IProduct | null;
}

interface IState {}

export class GiftMainImage extends React.Component<IProps, IState> {
    render() {
        if (!this.props.rootProduct) {
            return null;
        }
        let images: IProduct["images"] = [];
        if (this.props.variant) {
            images = images.concat(this.props.variant.images);
        }
        images = images.concat(this.props.rootProduct.images);
        if (images.length <= 0) {
            return null;
        }
        const image = images.filter((img) => {
            return img.role === "Product Tile";
        })[0];
        const altText = this.props.variant
            ? this.props.variant.title
            : this.props.rootProduct.title;
        return (
            <Image
                className="gift-main-image"
                src={image.original}
                alt={altText}
            />
        );
    }
}
