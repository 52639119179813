import * as t from "io-ts";
import { nullable } from "tsi-common-react/src/models/utils";
import { ProductID, ImageURL } from "tsi-common-react/src/models/nominals";
import {
    StructBlock,
    ListBlock,
    CharBlock,
    BooleanBlock,
    ImageChooserBlock,
    SnippetChooserBlock,
    BundleGroupChooserSubBlock,
    VariantHeroBlock,
    VariantDescriptionBlock,
} from "tsi-common-react/src/models/streamfield-blocks";
import { Link as LinkSnippet } from "tsi-common-react/src/models/streamfield-snippets";

/**
 * See streamfield block: tsi-tempurpedic/cms.blocks.reviews.ReviewsBlock
 */
const ReviewsBlockReview = StructBlock(
    {
        author: CharBlock,
        location: CharBlock,
        content: CharBlock,
    },
    {},
);

/**
 * See streamfield block: tsi-tempurpedic/cms.blocks.reviews.ReviewsBlock
 */
export const ReviewsBlock = StructBlock(
    {
        reviews: ListBlock(ReviewsBlockReview),
    },
    {},
);

/**
 * See streamfield block: tsi-tempurpedic/cms.pages.pdp.PDPUpsellBlock
 */
export const PDPUpsellBlockSlide = StructBlock(
    {
        image: ImageChooserBlock,
    },
    {
        slide_bundle: BundleGroupChooserSubBlock,
        slide_cta: CharBlock,
        slide_link: nullable(SnippetChooserBlock(LinkSnippet)),
        initial_mobile_slide: BooleanBlock,
    },
);
export interface IPDPUpsellBlockSlide
    extends t.TypeOf<typeof PDPUpsellBlockSlide> {}

/**
 * See streamfield block: tsi-tempurpedic/cms.pages.pdp.PDPUpsellBlock
 */
export const PDPUpsellBlock = StructBlock(
    {},
    {
        header_title: CharBlock,
        header_copy: CharBlock,
        header_title_mobile: CharBlock,
        header_copy_mobile: CharBlock,
        slides: ListBlock(PDPUpsellBlockSlide),
        cta_copy: CharBlock,
    },
);
export interface IPDPUpsellBlock extends t.TypeOf<typeof PDPUpsellBlock> {}

export const HotspotContent = t.interface({
    header_content: t.string,
    hotspot_one: t.string,
    hotspot_two: t.string,
    hotspot_three: t.string,
});

export interface IHotspotContent extends t.TypeOf<typeof HotspotContent> {}

/**
 * See Wagtail Page: tsi-tempurpedic/cms.pages.pdp.AbstractProductPage
 */
export const ProductPage = t.interface({
    products: t.array(ProductID),
    title: t.string,
    preheader: t.string,
    subhead: t.string,
    description: nullable(t.string),
    variant_configurator_description_data: t.array(VariantDescriptionBlock),
    shipping_description: nullable(t.string),
    shipping_icon_url: nullable(ImageURL),
    show_shipping_lead: t.boolean,
    show_financing_modal: t.boolean,
    show_more_ways: t.boolean,
    modules: t.string,
    bundle_modal_serialized: t.array(PDPUpsellBlock),
    variant_hero_images_with_urls: t.array(VariantHeroBlock),
    hero_image_url: nullable(ImageURL),
    mobile_hero_image_url: nullable(ImageURL),
    hero_image_alt: nullable(t.string),
    learn_more_target: t.string,
    live_chat_header: t.string,
});
export interface IProductPage extends t.TypeOf<typeof ProductPage> {}
