import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import { switchActiveElement, toggleActiveBlock } from "../utils/activeElem";
import { buildMobileNavToggleClickHandler } from "tsi-common-react/src/widgets/rich-navigation";

const registerEventListeners_Tabs = () => {
    // Tabbed content.
    Array.from(
        document.querySelectorAll<HTMLAnchorElement>("[data-tabs]"),
    ).forEach((elem, index) => {
        if (index !== 0) {
            elem.setAttribute("aria-selected", "false");
        } else {
            elem.setAttribute("aria-selected", "true");
        }

        elem.addEventListener("click", function (event) {
            event.preventDefault();
            const targetID =
                this.href.indexOf("#") !== -1
                    ? this.href.split("#").pop()
                    : null;
            const target = targetID ? document.getElementById(targetID) : null;
            if (!target) {
                return;
            }
            const block = this.dataset.tabs;
            if (!block) {
                return;
            }
            switchActiveElement(this, `${block}__tab`);
            switchActiveElement(target, `${block}__pane`);

            if (!this.parentNode) {
                return;
            }
            Array.from(this.parentNode.children).forEach((e) => {
                e.setAttribute("aria-selected", "false");
            });

            this.setAttribute("aria-selected", "true");
        });
    });

    // Toggle section behavior
    Array.from(
        document.querySelectorAll<HTMLElement>("[data-section-toggle]"),
    ).forEach((elem) => {
        elem.addEventListener("click", function (event) {
            event.preventDefault();
            // Toggle the class of the closest DOM element with the 'data-section-toggle-target' attribute
            // Selector is gnarly. To be refactored.
            const currClass = (this.className || "").split(" ")[0];
            const currAriaExpanded = this.getAttribute("aria-expanded");
            if (this.nextElementSibling) {
                const target = (
                    (this.nextElementSibling as HTMLElement).className || ""
                ).split(" ")[0];
                toggleActiveBlock(`.${target}`);
            }
            toggleActiveBlock(`.${currClass}`);

            // Handle this targets aria-label
            if (currAriaExpanded === "false") {
                this.setAttribute("aria-expanded", "true");
            } else if (currAriaExpanded === "true") {
                this.setAttribute("aria-expanded", "false");
            }
        });
    });
};

const registerEventListeners_Footer = () => {
    // Footer toggle behavior
    // TODO: Refactor data-section-toggle click function above to allow for this behavior
    Array.from(
        document.querySelectorAll<HTMLElement>(".footer-nav-mobile__header"),
    ).forEach((elem) => {
        elem.addEventListener("click", function () {
            if (this.parentElement) {
                this.parentElement.classList.toggle(
                    "footer-nav-mobile__block--active",
                );
            }
        });
    });
};

const registerEventListeners_Accordion = () => {
    setAccordionListener();
};

// Accordion logic exported to enable usage for ajax loaded accordions (like the offers page modal)
export const setAccordionListener = () => {
    // Accordion
    Array.from(document.querySelectorAll<HTMLElement>(".accordion")).forEach(
        (elem) => {
            if (elem.classList.contains("accordion--loaded")) {
                return;
            }
            elem.classList.add("accordion--loaded");
            elem.addEventListener("click", function (event) {
                event.preventDefault();
                const ariaExpanded = this.getAttribute("aria-expanded");
                if (ariaExpanded === "true" || ariaExpanded === "false") {
                    this.setAttribute(
                        "aria-expanded",
                        ariaExpanded === "true" ? "false" : "true",
                    );
                }

                this.classList.toggle("accordion--is-active");
                const parentElement = this.closest("li") || this.parentNode;
                if (!parentElement) {
                    return;
                }

                Array.from(parentElement.children)
                    .filter((e) => {
                        return e.classList.contains("accordion-target");
                    })
                    .forEach((e) => {
                        e.classList.toggle("accordion-target--is-active");
                    });
            });
        },
    );
};

const registerEventListeners_Search = () => {
    // Search bar functionality
    Array.from(
        document.querySelectorAll<HTMLElement>(".search-results__clear-icon"),
    ).forEach((elem) => {
        elem.addEventListener("click", () => {
            const input = document.querySelector<HTMLInputElement>(
                ".search-results__search-input",
            );
            if (input) {
                input.value = "";
            }
        });
    });
};

// Utility pages
const registerEventListeners_UtilityPages = () => {
    if (!document.body.classList.contains("utility-page")) {
        return;
    }
    // On load -- Tabs -- Add active class
    const firstTab = document.querySelector(".tabs__tab");
    if (firstTab) {
        firstTab.classList.add("tabs__tab--is-active");
    }
    const firstPane = document.querySelector(".tabs__pane");
    if (firstPane) {
        firstPane.classList.add("tabs__pane--is-active");
    }

    // Desktop
    // Onload -- left side nav -- Accordion -- add active class
    Array.from(
        document.querySelectorAll(".accordion__list-item--desktop .accordion"),
    ).forEach((elem) => {
        elem.classList.add("accordion--is-active");
    });
    Array.from(
        document.querySelectorAll(
            ".accordion__list-item--desktop .accordion-target",
        ),
    ).forEach((elem) => {
        elem.classList.add("accordion-target--is-active");
    });
};

// Order page
const registerEventListeners_OrderPage = () => {
    if (
        !document.body.classList.contains("orders-page") &&
        !document.body.classList.contains("thank-you-page")
    ) {
        return;
    }

    Array.from(
        document.querySelectorAll<HTMLElement>(
            ".order-summary-details__info-column--order-details-btn",
        ),
    ).forEach((elem) => {
        elem.addEventListener("click", function () {
            if (!this.parentNode) {
                return;
            }

            Array.from(this.parentNode.children)
                .filter((e) => {
                    return e.classList.contains(
                        "order-summary-details__info-column--order-details-btn",
                    );
                })
                .forEach((e) => {
                    if (!e.parentNode) {
                        return;
                    }
                    const detailHeader = e.parentNode?.parentNode
                        ?.parentNode as HTMLElement;
                    detailHeader.classList.toggle(
                        "order-summary-details__header--active",
                    );
                });

            this.classList.toggle(
                "order-summary-details__info-column--order-details-btn--close",
            );
        });
    });
};

// Mobile Toggles
const registerEventListeners_MobileToggle = () => {
    Array.from(
        document.querySelectorAll<HTMLElement>(".mobile-toggle"),
    ).forEach((elem) => {
        elem.addEventListener("click", buildMobileNavToggleClickHandler(elem));
    });
};

// Disable the accordion functionality of
// Rich Navigation Link Collections
const rich_navigations = document.querySelectorAll<HTMLElement>(
    "nav.rich-navigation",
);
if (rich_navigations) {
    Array.from(rich_navigations).forEach((rich_navigation) => {
        Array.from(
            rich_navigation.querySelectorAll(
                ".rich-navigation--link-collection",
            ),
        ).forEach((linkCollection) => {
            linkCollection
                .querySelector<HTMLElement>("button")
                ?.removeAttribute("aria-expanded");
            linkCollection
                .querySelector<HTMLElement>(".collection")
                ?.removeAttribute("aria-hidden");
        });
    });
}

// Dynamic Upsell Info Modal Panel Offset CSS Variable
const setUpsellInfoModalPanelOffset = (element: HTMLElement) => {
    const offsetValue = element.getBoundingClientRect().top;
    let offset = `${offsetValue}px`;
    if (offsetValue < 0) {
        offset = "0px";
    }
    document.documentElement.style.setProperty(
        "--configurator--upsell--panel-offset",
        offset,
    );
};

const checkUpsellInfoModalPanelOffset = () => {
    const content = document.getElementById("main-content");
    if (content) {
        setUpsellInfoModalPanelOffset(content);
    }
    window.addEventListener("scroll", () => {
        if (content) {
            setUpsellInfoModalPanelOffset(content);
        }
    });
};

// Dynamic Upsell Info Modal Overlay Footer Offset CSS Variable
const setUpsellInfoModalPanelFooterOffset = () => {
    const content = document.querySelector(".service-footer-container");
    if (content) {
        // this is the hardcoded height of the Service Footer
        const offset = "50px";
        document.documentElement.style.setProperty(
            "--configurator--upsell--panel-overlay-footer-offset",
            offset,
        );
    }
};

// Register functions after body loads
onDOMContentLoaded.on(() => {
    registerEventListeners_Tabs();
    registerEventListeners_Footer();
    registerEventListeners_Accordion();
    registerEventListeners_UtilityPages();
    registerEventListeners_OrderPage();
    registerEventListeners_Search();
    registerEventListeners_MobileToggle();
    checkUpsellInfoModalPanelOffset();
    setUpsellInfoModalPanelFooterOffset();
});
