import React from "react";
import { t } from "ttag";
import { Trans } from "../../../common/Trans";
import { FinancingModalTrigger } from "./Trigger";

import styles from "./Minicart.module.scss";

interface Props {
    closeModal: () => void;
}

export const FinancingModalTriggerBannerMinicart = (props: Props) => {
    return (
        <div className={styles.root}>
            <div className={styles.cardImageHolder} aria-hidden="true"></div>
            <Trans
                fmtString={t`<Trigger>Check if you Pre-Qualify</Trigger> <P>without
                    affecting your credit score</P>`}
                data={{
                    Trigger: (content) => (
                        <FinancingModalTrigger
                            key={1}
                            modalType="prequal-app"
                            onClick={props.closeModal}
                        >
                            {content}
                        </FinancingModalTrigger>
                    ),
                    P: (content) => <p key={2}>{content}</p>,
                }}
            />
        </div>
    );
};
