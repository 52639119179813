import React from "react";
import {
    ITabFeatureAttributeBlock_AttributeTab,
    ITabFeatureAttributeBlock_ModelTab,
} from "../models.interfaces";
import { tabFeatureAttributeBlock } from "../elementIDs";
import styles from "./TabFeatureAttributeBlockModelTabButton.module.scss";

interface IProps {
    attrIdx: number;
    attrTab: ITabFeatureAttributeBlock_AttributeTab;
    modelIdx: number;
    modelTab: ITabFeatureAttributeBlock_ModelTab;
    onClick: () => void;
    isSelected: boolean;
}

interface IState {}

export class TabFeatureAttributeBlockModelTabButton extends React.Component<
    IProps,
    IState
> {
    private readonly rootRef = React.createRef<HTMLButtonElement>();

    componentDidMount() {
        // Even though we did not want to, we manipulated
        // the hardcoded font size on mobile via javascript
        if (window.innerWidth < 500) {
            if (!this.rootRef.current) {
                return;
            }
            const siblingElements = this.rootRef.current.children;
            for (const element of siblingElements) {
                element.setAttribute("style", "font-size:12px");
                for (const altEl of element.children) {
                    altEl.setAttribute("style", "font-size:12px");
                }
            }
        }
    }

    render() {
        return (
            <button
                ref={this.rootRef}
                className={styles.root}
                id={tabFeatureAttributeBlock.modelTabButton(
                    this.props.attrTab,
                    this.props.attrIdx,
                    this.props.modelTab,
                    this.props.modelIdx,
                )}
                type="button"
                role="tab"
                aria-controls={tabFeatureAttributeBlock.modelTabContent(
                    this.props.attrTab,
                    this.props.attrIdx,
                    this.props.modelTab,
                    this.props.modelIdx,
                )}
                tabIndex={this.props.isSelected ? 0 : -1}
                aria-selected={this.props.isSelected}
                onClick={this.props.onClick}
                dangerouslySetInnerHTML={{
                    __html: this.props.modelTab.model_name,
                }}
            ></button>
        );
    }
}
