import { registerCascade } from "tsi-common-react/src/utils/cascade";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import { preferredLocationSelector } from "tsi-common-react/src/apps/common/selectors";
import { storeFilterSelector } from "tsi-common-react/src/apps/retail/selectors";
import { store } from "../store";
import { Dispatchers } from "./dispatchers";
import { getStores, getFlagshipStorePage } from "./loaders";
import {
    selectedProductSelector,
    nearestFlagshipStoreSelector,
    getFlagshipStoreByExternalID,
} from "./selectors";

const dispatchers = new Dispatchers(store.dispatch);

// Load Stores when location changes
registerCascade(store, {
    debounce: 500,

    transformState: (state) => {
        return state;
    },

    getKey: (state) => {
        // prefer entered location zip over detected location zip
        const location = preferredLocationSelector(state);
        const selectedProduct = selectedProductSelector(state);
        // Trigger new request if filter changes
        const storeFilters = storeFilterSelector(state);
        return `${JSON.stringify(location)}${selectedProduct}${JSON.stringify(
            storeFilters,
        )}`;
    },

    doCascade: async (key, state) => {
        if (key === state.retail.storesLoadedKey) {
            return true;
        }
        const location = preferredLocationSelector(state);
        const selectedProduct = selectedProductSelector(state);
        const storeFilters = storeFilterSelector(state);
        if (!location || !storeFilters) {
            return false;
        }
        // Load Stores
        const { allStores, flagshipStores, storesWithProduct } =
            await getStores(location, storeFilters, selectedProduct);
        // Deduplicate and sort stores
        const storesIDs: Set<ISyncStoreID> = new Set();
        const stores = [...allStores, ...flagshipStores, ...storesWithProduct]
            .reduce<IRetailStoreWithDistance[]>((memo, s) => {
                if (!storesIDs.has(s.external_id)) {
                    storesIDs.add(s.external_id);
                    memo.push(s);
                }
                return memo;
            }, [])
            .sort((a, b) => {
                if (a.distance === b.distance) {
                    return 0;
                }
                return a.distance > b.distance ? 1 : -1;
            });
        // Update Redux data
        dispatchers.setStores(key, stores);
        if (selectedProduct) {
            dispatchers.setProductAvailability(
                selectedProduct,
                storesWithProduct,
            );
        }
        return true;
    },
});

// When nearest flagship store changes, load the corresponding CMS Page
registerCascade(store, {
    debounce: 500,

    transformState: (state) => {
        return state;
    },

    getKey: (state) => {
        const nearestFlagshipStore = nearestFlagshipStoreSelector(state);
        return `${nearestFlagshipStore ? nearestFlagshipStore.external_id : 0}`;
    },

    doCascade: async (_, state) => {
        const nearestFlagshipStore = nearestFlagshipStoreSelector(state);
        if (!nearestFlagshipStore) {
            return true;
        }
        // No need to load the page if it's already in Redux state
        if (
            getFlagshipStoreByExternalID(
                state.retail.flagshipStorePages,
                nearestFlagshipStore.external_id,
            )
        ) {
            return true;
        }
        try {
            const cmsStore = await getFlagshipStorePage(
                nearestFlagshipStore.external_id,
            );
            dispatchers.addFlagshipStorePages([cmsStore]);
        } catch (e) {
            console.error(e);
        }
        return true;
    },
});
