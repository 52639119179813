import { connect } from "react-redux";
import {
    IGalleryImage,
    IProps as IGalleryProps,
    Gallery as GalleryComponent,
} from "tsi-common-react/src/common/Gallery";

const mapStateToProps = (): IGalleryProps => {
    const rawBlocks = document.querySelectorAll<HTMLElement>(
        ".pdp-carousel-block-js",
    );
    const carouselBlocks = rawBlocks ? Array.from(rawBlocks) : [];
    const galleryImages = carouselBlocks.map((item): IGalleryImage => {
        return {
            originalURL: item.dataset.originalUrl || "",
            originalAlt: item.dataset.originalAlt || "",
            thumbnailURL: item.dataset.thumbnailUrl || "",
            thumbnailAlt: item.dataset.thumbnailAlt || "",
            caption: item.dataset.caption || "",
        };
    });
    return {
        images: galleryImages,
        alternateArrows: true,
    };
};

export const Gallery = connect(mapStateToProps)(GalleryComponent);
