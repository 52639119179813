import * as t from "io-ts";
import { SyncStoreID, WebPageURL } from "tsi-common-react/src/models/nominals";
import { nullable } from "tsi-common-react/src/models/utils";

export const CMSFlagshipStorePage = t.interface({
    id: t.number,
    meta: t.interface({
        type: t.string,
        detail_url: t.string,
        html_url: WebPageURL,
        slug: t.string,
        first_published_at: t.string,
        sync_id: SyncStoreID,
    }),
    title: t.string,
    address: t.string,
    address2: t.string,
    city: t.string,
    state: t.string,
    postal: t.string,
    storefront: nullable(
        t.interface({
            id: t.number,
            meta: t.interface({
                type: t.string,
                detail_url: t.string,
                download_url: t.string,
            }),
            title: t.string,
        }),
    ),
    storefront_thumbnail: nullable(
        t.interface({
            url: t.string,
            width: t.number,
            height: t.number,
        }),
    ),
    hours_sun: t.string,
    hours_mon: t.string,
    hours_tues: t.string,
    hours_wed: t.string,
    hours_thurs: t.string,
    hours_fri: t.string,
    hours_sat: t.string,
    hours_summary: t.string,
    hours_summary_abbr: t.string,
    located_near: t.string,
    shopping_district: t.string,
});

export const CMSFlagshipStorePages = t.array(CMSFlagshipStorePage);

export const CMSFlagshipStorePagesAPIPage = t.interface({
    meta: t.interface({
        total_count: t.number,
    }),
    items: CMSFlagshipStorePages,
});

export const FeaturedRetailerLocationsBlockConfig = t.interface({
    retailer_name: t.string,
    displayed_retailers: t.array(t.string),
});
