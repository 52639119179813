import React from "react";
import SVG from "react-inlinesvg";
import iconFinancingCheck from "../../../../img/icons/financing-check.svg";

import styles from "./index.module.scss";

export const FinancingFortivaApprovalHeader = (props: { text: string }) => {
    return (
        <>
            <SVG className={styles.fortivaCheckIcon} src={iconFinancingCheck} />
            <h2 className={styles.heading}>{props.text}</h2>
        </>
    );
};
