import React from "react";
import { connect } from "react-redux";
import { IImageURL, isoImageURL } from "../../../models/nominals";
import { IProduct, IOptionValues } from "../../../models/catalogue.interfaces";
import { VariantHeroBlock } from "../../../models/streamfield-blocks";
import { TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { upgradedRootProductSelector } from "../selectors";

interface IOwnProps {
    defaultBackgroundImage: IImageURL | null;
    defaultMobileBackgroundImage: IImageURL | null;
    defaultImageTitle: string;
    variantHeroInfo?: VariantHeroBlock[];
}

interface IReduxProps {
    selectedRootProduct: IProduct | null;
    selectedValues: IOptionValues;
}

interface HeroImage {
    desktop: IImageURL | null;
    mobile: IImageURL | null;
    alt: string;
}

type IProps = IOwnProps & IReduxProps;

interface IState {}

class PDPHeroComponent extends React.Component<IProps, IState> {
    private getHeroImage(): HeroImage {
        const defaultImage: HeroImage = {
            desktop: this.props.defaultBackgroundImage,
            mobile: this.props.defaultMobileBackgroundImage,
            alt: this.props.defaultImageTitle,
        };
        if (!this.props.variantHeroInfo || !this.props.selectedValues) {
            return defaultImage;
        }
        const sortedHeroImages = this.props.variantHeroInfo
            .map((hero) => {
                let score = 0;
                if (hero.product === this.props.selectedRootProduct?.id) {
                    score += 2;
                }
                if (hero.product === null) {
                    score += 1;
                }
                /* eslint-disable @typescript-eslint/no-explicit-any */
                if (
                    hero.value ===
                    this.props.selectedValues[
                        hero.attribute as any as keyof IOptionValues
                    ]
                ) {
                    score += 1;
                }
                /* eslint-enable @typescript-eslint/no-explicit-any */
                return {
                    score: score,
                    hero: hero,
                };
            })
            .sort((a, b) => {
                if (a.score === b.score) {
                    return 0;
                }
                return a.score > b.score ? -1 : 1;
            });
        const imageToUse =
            sortedHeroImages.length > 0 ? sortedHeroImages[0].hero : null;
        if (!imageToUse) {
            return defaultImage;
        }
        return {
            desktop: imageToUse.image.url,
            mobile: imageToUse.mobile_image?.url || null,
            alt: imageToUse.image.title,
        };
    }

    render() {
        const heroImg = this.getHeroImage();
        return (
            <picture className="pdp-hero__image">
                {heroImg.mobile && (
                    <source
                        srcSet={isoImageURL.unwrap(heroImg.mobile)}
                        media="(max-width: 768px)"
                    ></source>
                )}
                {heroImg.desktop && (
                    <img
                        src={isoImageURL.unwrap(heroImg.desktop)}
                        alt={heroImg.alt}
                    />
                )}
            </picture>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        selectedRootProduct: upgradedRootProductSelector(state),
        selectedValues: state.ui.optionValues,
        ...ownProps,
    };
};

export const PDPHero = connect(mapStateToProps)(PDPHeroComponent);
