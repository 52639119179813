import React from "react";
import { t } from "ttag";
import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";
import { getDinero, getMonthlyPrice } from "../../../utils/money";
import { urls } from "../../../utils/urls";
import { getLowestThresholdPlan } from "../../../utils/financing";
import { money, formatAPR, formatTermLength } from "../../../utils/format";
import { Trans } from "../../../common/Trans";
import { Link } from "../../../common/Link";
import styles from "./FinancingUpsellBlock.module.scss";

interface IProps {
    grandTotal: string;
}

export const FinancingUpsellBlock = (props: IProps) => {
    const grandTotal = getDinero(props.grandTotal);
    const lowestPlan = getLowestThresholdPlan();
    if (!lowestPlan) {
        return null;
    }
    // Already meet plan threshold?
    if (grandTotal.greaterThanOrEqual(lowestPlan.threshold)) {
        return null;
    }
    const orderTotalDelta = lowestPlan.threshold.subtract(grandTotal);
    const costPerMonth = getMonthlyPrice(
        lowestPlan.threshold,
        lowestPlan.length,
    );
    return (
        <div className={styles.root}>
            <p className={styles.headline}>
                {interpolate(
                    t`Special financing on orders of %(threshold)s or more!`,
                    { threshold: money(lowestPlan.threshold) },
                )}
            </p>
            <p className={styles.marginBottom}>
                {interpolate(
                    t`Add products totaling %(threshold)s or more to qualify for %(apr)s APR for %(length)s months`,
                    {
                        threshold: money(orderTotalDelta),
                        apr: formatAPR(lowestPlan.apr),
                        length: formatTermLength(lowestPlan.length),
                    },
                )}
                <sup>1</sup>.
            </p>
            <div className={styles.cta}>
                <div className={styles.ctaTitle}>
                    {t`Monthly Payments starting at:`}
                </div>
                <div className={styles.ctaCopy}>
                    <Trans
                        fmtString={interpolate(
                            t`%(costPerMonth)s/mo.<Superscript1></Superscript1> for %(length)s months<Superscript2></Superscript2> `,
                            {
                                costPerMonth: `${money(costPerMonth)}`,
                                length: `${lowestPlan.length}`,
                            },
                        )}
                        data={{
                            Superscript1: () => <sup key="1">2</sup>,
                            Superscript2: () => (
                                <sup key="2">{lowestPlan.superscript}</sup>
                            ),
                        }}
                    />
                    {interpolate(t`(total payment of %(threshold)s`, {
                        threshold: money(lowestPlan.threshold),
                    })}
                </div>
            </div>
            <p className={styles.link}>
                <Link href={urls.pageURL("home")}>{t`Continue Shopping`}</Link>
            </p>
        </div>
    );
};
