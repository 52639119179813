document
    .querySelectorAll<HTMLElement>(".finance-faq__see-more")
    .forEach((elem) => {
        elem.addEventListener("click", () => {
            // Hide see more button
            elem.classList.add("finance-faq__see-more--hidden");
            // Shiow hidden FAQ items
            const hiddenFAQs = document.querySelectorAll<HTMLElement>(
                ".finance-faq--hidden",
            );
            hiddenFAQs.forEach((article) => {
                article.classList.remove("finance-faq--hidden");
            });
        });
    });
