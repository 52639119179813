import React from "react";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import { ajax } from "tsi-common-react/src/utils/ajax";
import { ITXTSendStorePostData } from "../models.interfaces";
import { LocatorSendResultsModal } from "./LocatorSendResults_Modal";
import { buildStoreData } from "../utils";
import iconIconTxt from "../../img/retail-locator/icon-txt.svg";

interface IProps {
    stores: IRetailStoreWithDistance[];
    getStoreNumber: (store: IRetailStoreWithDistance) => number;
}

interface IState {}

export class LocatorSendResultsText extends React.Component<IProps, IState> {
    state: IState = {};

    private readonly onSubmit = async (
        recipient: string,
        selectedStores: Set<ISyncStoreID>,
    ) => {
        const data: ITXTSendStorePostData = {
            brand: "TP",
            triggerExternalCall: true,
            program: "text",
            phone: recipient,
            stores: buildStoreData(this.props.stores, selectedStores),
        };
        await ajax
            .post("https://api.promosis.com/api/v1/tsi-2249/text")
            .send(data);
    };

    render() {
        return (
            <LocatorSendResultsModal
                modalClassName="modal-txt"
                buttonLabel="Text Results"
                buttonImgSrc={iconIconTxt}
                buttonImgAlt="Text Results"
                modalTitle="Text Your Results"
                modalDescription={
                    <>
                        Check up to <strong>3</strong> <span>results</span> you
                        would like texted.
                        <br />
                        Message and data rates may apply.
                    </>
                }
                inputType="phone"
                inputPlaceholder="Enter a Phone Number"
                submitButtonLabel="Send"
                maxSelected={3}
                stores={this.props.stores}
                getStoreNumber={this.props.getStoreNumber}
                onSubmit={this.onSubmit}
            />
        );
    }
}
