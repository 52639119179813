import React from "react";
import Flickity from "flickity-imagesloaded";
import { check } from "tsi-common-react/src/models/utils";
import { ReviewsBlock as ReviewsBlockCodec } from "../models";
import classNames from "classnames";
import styles from "./ReviewsBlock.module.scss";

interface IProps {
    reviewsBlockJSON: string;
    theme?: string;
}

interface IState {}

export class ReviewsBlock extends React.Component<IProps, IState> {
    private carousel: HTMLDivElement | null = null;
    private flickity: Flickity | null = null;
    private readonly THEME_STARS_BG = "theme--stars-and-bg";

    componentDidMount() {
        if (!this.carousel) {
            return;
        }
        if (!this.isStarsTheme() && this.carousel.childNodes.length > 1) {
            if (this.flickity) {
                this.flickity.destroy();
            }
            this.flickity = new Flickity(this.carousel, {
                watchCSS: true,
                wrapAround: true,
                prevNextButtons: false,
                pageDots: true,
            });
        }
    }

    private isStarsTheme() {
        return this.props.theme === this.THEME_STARS_BG;
    }

    private renderAuthor(author: string, location: string) {
        if (this.isStarsTheme()) {
            return (
                <>
                    – <em>{author}</em> in {location}
                </>
            );
        }
        return (
            <>
                <strong>{author}</strong>, {location}
            </>
        );
    }

    render() {
        const reviewsBlock = check(
            ReviewsBlockCodec.decode(JSON.parse(this.props.reviewsBlockJSON)),
        );
        const columnClasses = classNames({
            [styles.column]: true,
            [styles.columnThemeStars]: this.isStarsTheme(),
        });
        const contentClasses = classNames({
            [styles.content]: true,
            [styles.contentThemeStars]: this.isStarsTheme(),
        });
        const authorClasses = classNames({
            [styles.author]: true,
            [styles.authorThemeStars]: this.isStarsTheme(),
        });

        return (
            <div
                className={styles.columns}
                ref={(ref) => {
                    this.carousel = ref;
                }}
            >
                {reviewsBlock.reviews.map((review, i) => (
                    <div className={columnClasses} key={i}>
                        <div className={contentClasses}>{review.content}</div>
                        <div className={authorClasses}>
                            {this.renderAuthor(review.author, review.location)}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}
