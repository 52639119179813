import { onReadyStateComplete } from "tsi-common-react/src/utils/events";
import { MOBILE_WIDTH_THRESHOLD_ALTERNATIVE } from "tsi-common-react/src/apps/configurator/constants";

const isMobileWidth = () => {
    return window.innerWidth < MOBILE_WIDTH_THRESHOLD_ALTERNATIVE;
};

const topStickyConfiguratorContainer = document.querySelector<HTMLElement>(
    ".pdp-sticky-configurator",
);

export const hideTopStickyConfigurator = (
    hide: boolean,
    element: HTMLElement | null = null,
) => {
    if (!isMobileWidth() || !topStickyConfiguratorContainer) {
        return;
    }
    if (element) {
        if (hide) {
            element.classList.add("hide");
        } else {
            element.classList.remove("hide");
        }
    } else {
        if (hide) {
            topStickyConfiguratorContainer.classList.add("hide");
        } else {
            topStickyConfiguratorContainer.classList.remove("hide");
        }
    }
};

const triggerStickyRail = () => {
    let LARGE_SCREEN_TARGET = 0;
    const searchNav = document.querySelector<HTMLElement>(".search-nav");
    const siteHeader = document.querySelector<HTMLElement>(".site-header");
    const configurator = document.querySelector<HTMLElement>(
        ".pdp-hero-alternative .pdp-hero__content",
    );
    const rail = document.querySelector<HTMLElement>(".pdp-hero-alternative");
    const footer = document.querySelector<HTMLElement>(".site-footer");

    Array.from([searchNav, siteHeader], (elem) => {
        LARGE_SCREEN_TARGET += elem ? elem.offsetHeight : 0;
    });

    window.addEventListener("scroll", () => {
        const TARGET = LARGE_SCREEN_TARGET;
        if (!isMobileWidth()) {
            if (configurator) {
                if (
                    window.scrollY >= TARGET &&
                    TARGET === LARGE_SCREEN_TARGET
                ) {
                    configurator.classList.add("sticky");
                    if (rail) {
                        const threshold =
                            rail.offsetHeight +
                            TARGET -
                            configurator.scrollHeight;
                        const yPosition =
                            rail.offsetHeight - configurator.scrollHeight;
                        if (
                            window.scrollY >= threshold &&
                            yPosition >= TARGET
                        ) {
                            configurator.classList.remove("sticky");
                            configurator.classList.add("static");
                            configurator.style.top = `${yPosition}px`;
                        } else {
                            configurator.classList.add("sticky");
                            configurator.classList.remove("static");
                            configurator.style.top = `0`;
                        }
                    }
                } else {
                    configurator.classList.remove("sticky");
                }
            }
        } else {
            if (configurator) {
                configurator.classList.remove("sticky");
            }
            if (topStickyConfiguratorContainer) {
                const topStickyConfigurator =
                    topStickyConfiguratorContainer.querySelector<HTMLElement>(
                        ".sticky-configurator",
                    );
                if (footer && topStickyConfigurator) {
                    if (
                        window.scrollY >=
                        footer.getBoundingClientRect().top -
                            document.body.getBoundingClientRect().top -
                            topStickyConfigurator.offsetHeight
                    ) {
                        hideTopStickyConfigurator(true, topStickyConfigurator);
                    } else {
                        hideTopStickyConfigurator(false, topStickyConfigurator);
                    }
                }
            }
        }
    });

    window.addEventListener("resize", () => {
        if (isMobileWidth()) {
            if (configurator) {
                configurator.classList.remove("sticky");
            }
        }
    });
};

onReadyStateComplete.on(async () => {
    triggerStickyRail();
});
