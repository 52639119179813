import React, { useRef, useEffect } from "react";
import SVG from "react-inlinesvg";
import { useAppDispatch } from "../../reducers";
import { ModalFullApplyPending } from "../models";
import { refreshPendingAppStatus } from "../reducers";
import { TitleText } from "./TitleText";
import { BodyContent } from "./BodyContent";
import iconCircle from "../../../../img/icons/circle.svg";

import styles from "./index.module.scss";

interface Props {
    modalState: ModalFullApplyPending;
}

const now = () => new Date().getTime();

const PENDING_APPROVAL_CHECK_TIMEOUT = 90_000;
const PENDING_APPROVAL_CHECK_POLL_INTERVAL = 15_000;

export const FinancingFullPending = (props: Props) => {
    const firstMountTime = useRef<number>(now());
    const refreshTimer = useRef<number | null>(null);
    const dispatch = useAppDispatch();

    const refreshAppStatus = async () => {
        // If we're past out timeout, stop refreshing.
        const timeElapsed = now() - firstMountTime.current;
        if (timeElapsed >= PENDING_APPROVAL_CHECK_TIMEOUT) {
            return;
        }
        // Not past the timeout, so start the refresh.
        await dispatch(refreshPendingAppStatus(props.modalState));
        // Schedule the next refresh.
        scheduleRefresh();
    };

    const scheduleRefresh = () => {
        if (refreshTimer.current) {
            window.clearTimeout(refreshTimer.current);
        }
        refreshTimer.current = window.setTimeout(
            refreshAppStatus,
            PENDING_APPROVAL_CHECK_POLL_INTERVAL,
        );
    };

    useEffect(() => {
        // On mount, schedule a periodic refresh of the app status.
        scheduleRefresh();
        // On unmount, cancel the periodic refresh.
        return () => {
            if (refreshTimer.current) {
                window.clearTimeout(refreshTimer.current);
            }
        };
    }, []);

    return (
        <div className={styles.wrapper}>
            <SVG className={styles.icon} src={iconCircle} />
            <h3 className={styles.title}>
                <TitleText isRefreshing={props.modalState.isRefreshing} />
            </h3>
            <BodyContent
                isRefreshing={props.modalState.isRefreshing}
                firstName={props.modalState.values.main_applicant__first_name}
            />
        </div>
    );
};
