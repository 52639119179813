import { slugify } from "../../utils/text";
import {
    ITabFeatureAttributeBlock_AttributeTab,
    ITabFeatureAttributeBlock_ModelTab,
} from "./models.interfaces";

export const tabFeatureAttributeBlock = {
    attributeTabButton: (
        attrTab: ITabFeatureAttributeBlock_AttributeTab,
        idx: number,
    ) => {
        return `attribute-tab-${idx}-${slugify(attrTab.attribute_value)}`;
    },

    attributeTabContent: (
        attrTab: ITabFeatureAttributeBlock_AttributeTab,
        idx: number,
    ) => {
        return `attribute-panel-${idx}-${slugify(attrTab.attribute_value)}`;
    },

    modelTabButton: (
        attrTab: ITabFeatureAttributeBlock_AttributeTab,
        attrTabIdx: number,
        modelTab: ITabFeatureAttributeBlock_ModelTab,
        modelTabIdx: number,
    ) => {
        return `model-tab-${attrTabIdx}-${slugify(
            attrTab.attribute_value,
        )}-${modelTabIdx}-${slugify(modelTab.model_name)}`;
    },

    modelTabContent: (
        attrTab: ITabFeatureAttributeBlock_AttributeTab,
        attrTabIdx: number,
        modelTab: ITabFeatureAttributeBlock_ModelTab,
        modelTabIdx: number,
    ) => {
        return `model-panel-${attrTabIdx}-${slugify(
            attrTab.attribute_value,
        )}-${modelTabIdx}-${slugify(modelTab.model_name)}`;
    },
};
