import React from "react";
import styles from "./SVGLoader.module.scss";

interface IProps {
    className?: string;
}

export const SVGLoader = (props: IProps) => {
    return (
        <div className={`${styles.container} ${props.className || ""}`}></div>
    );
};
