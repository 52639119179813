/**
 * Steps types for an auto generated multi-step form.
 */
export enum FormStepType {
    FieldSet,
    OtherJSX,
}

/**
 * A single step in a multi-step form that renders a set of form fields.
 */
export interface FormStepFieldSet<FieldNames extends string> {
    stepType: FormStepType.FieldSet;
    groupTitle: string;
    fields: Readonly<Array<FieldNames>>;
}

/**
 * A single step in a multi-step form that renders some arbitrary JSX.
 */
export interface FormStepOtherJSX {
    stepType: FormStepType.OtherJSX;
    buildContent: (index: number) => JSX.Element;
    summary?: JSX.Element;
}

/**
 * Union type for representing any form step
 */
export type FormStep<FieldNames extends string> =
    | FormStepFieldSet<FieldNames>
    | FormStepOtherJSX;
