import { onReadyStateComplete } from "tsi-common-react/src/utils/events";

onReadyStateComplete.on(async () => {
    const columsCarouselBlocks = document.querySelectorAll<HTMLElement>(
        ".gallery-carousel-block",
    );
    if (!columsCarouselBlocks) {
        return;
    }
    const Flickity = (await import("flickity-imagesloaded")).default;
    columsCarouselBlocks.forEach((block) => {
        const gallery = block.querySelector<HTMLElement>(
            ".gallery-carousel-block__container",
        );
        if (!gallery) {
            return;
        }

        const flickity = new Flickity(gallery, {
            watchCSS: true,
            autoPlay: false,
            imagesLoaded: true,
            pageDots: false,
            prevNextButtons: true,
        });

        const buttonGroup = block.querySelector<HTMLElement>(
            ".gallery-carousel-block .buttons",
        );
        if (!buttonGroup) {
            return;
        }

        buttonGroup.addEventListener("click", (event) => {
            const target = event.target as HTMLButtonElement;
            const parent = target.parentNode as HTMLDivElement;
            const index = Array.prototype.indexOf.call(
                parent.children,
                event.target,
            );
            flickity.select(index);
        });

        flickity.on("select", (index) => {
            const selectedButton = buttonGroup.children[Number(index)];
            Array.from(buttonGroup.children, (button) => {
                if (button !== selectedButton) {
                    button.classList.remove("is-selected");
                    button.setAttribute("aria-current", "false");
                } else {
                    button.classList.add("is-selected");
                    button.setAttribute("aria-current", "true");
                }
            });
        });
    });
});
