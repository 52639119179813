import {
    searchStores,
    StoreCategory,
    IQueryParams,
} from "tsi-common-react/src/api/retail";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "tsi-common-react/src/models/location.interfaces";
import {
    ISyncStoreID,
    IProductUUID,
    isoMiles,
} from "tsi-common-react/src/models/nominals";
import { IStoreSearchFilters } from "tsi-common-react/src/apps/retail/models.interfaces";
import { check } from "tsi-common-react/src/models/utils";
import { ICMSFlagshipStorePage } from "./models.interfaces";
import { CMSFlagshipStorePage, CMSFlagshipStorePagesAPIPage } from "./models";
import { ajax } from "tsi-common-react/src/utils/ajax";
import {
    isLatLngLocation,
    isZipLocation,
} from "tsi-common-react/src/utils/guards";

const buildBaseQuery = (location: ILocation): IQueryParams | null => {
    if (isLatLngLocation(location)) {
        return {
            origLat: location.lat,
            origLng: location.lng,
        };
    }
    // search by zip
    if (isZipLocation(location)) {
        return {
            q: location.zip,
        };
    }
    return null;
};

export const getStores = async (
    location: ILocation,
    filters: IStoreSearchFilters,
    product?: IProductUUID | null,
) => {
    const baseQuery = buildBaseQuery(location);
    if (!baseQuery) {
        return {
            allStores: [],
            flagshipStores: [],
            storesWithProduct: [],
        };
    }
    const allStores = await searchStores({
        ...baseQuery,
        distance: isoMiles.unwrap(filters.distance),
    });
    const flagshipStores = await searchStores({
        ...baseQuery,
        category: StoreCategory.FLAGSHIP,
    });
    const storesWithProduct = product
        ? await searchStores({ ...baseQuery, product: product })
        : [];
    return {
        allStores: allStores,
        flagshipStores: flagshipStores,
        storesWithProduct: storesWithProduct,
    };
};

export const getClosestStores = async (
    product: IProductUUID,
    location: ILocation,
): Promise<IRetailStoreWithDistance[]> => {
    const baseQuery = buildBaseQuery(location);
    if (!baseQuery) {
        return [];
    }
    return searchStores({
        ...baseQuery,
        product: product,
    });
};

export const getClosestFlagshipStores = async (
    location: ILocation,
): Promise<IRetailStoreWithDistance[]> => {
    const baseQuery = buildBaseQuery(location);
    if (!baseQuery) {
        return [];
    }
    return searchStores({
        ...baseQuery,
        category: StoreCategory.FLAGSHIP,
    });
};

export const getStoreByExternalID = async (
    location: ILocation,
    externalID: ISyncStoreID,
): Promise<IRetailStoreWithDistance[]> => {
    const baseQuery = buildBaseQuery(location);
    if (!baseQuery) {
        return [];
    }
    return searchStores({
        ...baseQuery,
        external_id: externalID,
    });
};

export const getFlagshipStorePage = async (
    externalID: ISyncStoreID,
): Promise<ICMSFlagshipStorePage> => {
    const resp = await ajax
        .get(`/api/cms/v2/flagship-pages/${externalID}/`)
        .set("Accept", "application/json");
    return check(CMSFlagshipStorePage.decode(resp.body));
};

export const getAllFlagshipStorePages = async (): Promise<
    ICMSFlagshipStorePage[]
> => {
    const loadPage = async (offset = 0) => {
        const resp = await ajax
            .get("/api/cms/v2/flagship-pages/")
            .query({
                offset: offset,
            })
            .set("Accept", "application/json");
        return check(CMSFlagshipStorePagesAPIPage.decode(resp.body));
    };
    let results = await loadPage();
    let pages: ICMSFlagshipStorePage[] = results.items;
    while (pages.length < results.meta.total_count) {
        results = await loadPage(pages.length);
        pages = pages.concat(results.items);
    }
    return pages;
};
