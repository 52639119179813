import { decorateElements } from "tsi-common-react/src/utils/react";

decorateElements(".bed-bases-selection__content", async (elems) => {
    const Flickity = (await import("flickity")).default;
    for (const tabList of elems) {
        new Flickity(tabList, {
            watchCSS: true,
            draggable: true,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: true,
        });
    }
});
