import React from "react";
import { t } from "ttag";
import Modal from "react-modal";
import SVG from "react-inlinesvg";
import { focusElement } from "../../../utils/keyboardFocus";
import styles from "./EmailModal.module.scss";
import { EmailCaptureContent, IEmailModalSnippet } from "./EmailCaptureContent";
import iconXClose from "../../../../img/icons/x-close.svg";

interface IProps {
    isOpen: boolean;
    onClose: (
        event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => void;
    content: IEmailModalSnippet;
}

interface IState {
    contentHeight: number;
}

export class EmailModal extends React.Component<IProps, IState> {
    private contentElem: HTMLDivElement | undefined | null;

    public state: IState = {
        contentHeight: 360,
    };

    private setContentHeight(height?: number) {
        let newHeight = 100;
        if (height) {
            newHeight = height;
        } else if (this.contentElem) {
            newHeight = this.contentElem.getBoundingClientRect().height;
        }

        this.setState({
            contentHeight: newHeight,
        });
    }

    render() {
        // This and the related pieces are a way to guarantee react-modal's height
        // conforms to the height of the richtext inside it.
        const style = {
            overlay: {
                backgroundColor: "rgba(0,0,0,0.2)",
            },
            content: {
                height: `${this.state.contentHeight + 2}px`,
            },
        };

        return (
            <Modal
                onAfterOpen={() => {
                    this.setContentHeight();
                    focusElement(".email-modal__close");
                }}
                contentLabel="Buy Direct For Exclusive Benefits"
                aria={{ modal: true }}
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onClose}
                role="dialog"
                overlayClassName={
                    "service-footer-container__react-modal-overlay"
                }
                className={styles.root}
                style={style}
            >
                <div>
                    <button
                        className={`${styles.close} email-modal__close`}
                        onClick={this.props.onClose}
                        aria-label={t`close`}
                    >
                        <SVG
                            aria-hidden={true}
                            className={styles.closeIcon}
                            src={iconXClose}
                            title={t`Close Icon`}
                        />
                    </button>
                    <div
                        ref={(ref) => {
                            this.contentElem = ref;
                        }}
                    >
                        <EmailCaptureContent
                            content={this.props.content}
                            onSubmit={this.setContentHeight.bind(this)}
                        />
                    </div>
                    <aside>
                        {this.props.content.email_snippet.image && (
                            <img src={this.props.content.email_snippet.image} />
                        )}
                    </aside>
                </div>
            </Modal>
        );
    }
}
