import { onReadyStateComplete } from "tsi-common-react/src/utils/events";
import { store } from "../store";
import { hideTopStickyConfigurator } from "./configurator-sticky";

const productCompareTileSlider = () => {
    const gridContainer =
        document.querySelector<HTMLDivElement>(".comparison-grid");
    if (!gridContainer) {
        return;
    }
    const col = document.querySelector<HTMLDivElement>(".comparison-grid .col");
    const colWidth = col ? col.offsetWidth : 0;
    const compareRows = Array.from(
        document.querySelectorAll<HTMLDivElement>(
            ".comparison-grid .row .content",
        ),
    );
    const colContainer = document.querySelector<HTMLDivElement>(
        ".comparison-grid .row .content .comparison",
    );
    const colCount = colContainer ? colContainer.childElementCount : 0;
    let clickableCount = Math.round(colCount - 2);

    const SMALL_SCREEN_WIDTH_THRESHOLD = 946;
    const carouselColumnsNumber = 2;
    let selectedColumnsRange = [...Array(carouselColumnsNumber).keys()];
    const isSmallScreenWidth = () => {
        return window.innerWidth < SMALL_SCREEN_WIDTH_THRESHOLD;
    };

    const removeGridAttributes = (...attrs: string[]) => {
        attrs.forEach((attr) => {
            gridContainer.removeAttribute(attr);
        });
    };

    const setGridAttributes = () => {
        gridContainer.setAttribute("role", "region");
        gridContainer.setAttribute("aria-label", "Comparison Carousel");
    };

    const checkColumnVisibility = (range: number[]) => {
        // This setTimeout hack alows to check removed columns.
        setTimeout(() => {
            const visibleColumns = document.querySelectorAll<HTMLDivElement>(
                ".comparison-grid div:first-of-type .content .comparison .col:not(.removed)",
            );
            clickableCount = Math.round(visibleColumns.length - 2);
            const rows = document.querySelectorAll<HTMLElement>(
                ".product-compare-page-block .comparison-grid>.row>.content .comparison",
            );
            const attributeGridRows = document.querySelectorAll<HTMLElement>(
                ".attribute-grid-row",
            );
            const productCtas = document.querySelectorAll<HTMLElement>(
                ".product-compare-page-block .comparison-grid .product-ctas",
            );

            const toggleColumnClass = (rowElements: HTMLElement[]) => {
                rowElements.map((row) => {
                    const columns =
                        row.querySelectorAll<HTMLElement>(".col:not(.removed)");
                    Array.from(columns).map((column, index) => {
                        if (isSmallScreenWidth() && !range.includes(index)) {
                            column.classList.add("col--hide");
                        } else {
                            column.classList.remove("col--hide");
                        }
                    });
                });
            };
            toggleColumnClass(Array.from(rows));
            toggleColumnClass(Array.from(attributeGridRows));
            toggleColumnClass(Array.from(productCtas));
        }, 100);
    };
    if (isSmallScreenWidth()) {
        checkColumnVisibility(selectedColumnsRange);
    } else {
        removeGridAttributes("role", "aria-label");
    }
    window.addEventListener("resize", () => {
        checkColumnVisibility(selectedColumnsRange);
        if (isSmallScreenWidth()) {
            setGridAttributes();
        } else {
            removeGridAttributes("comparison-grid", "role", "aria-label");
        }
    });

    const removeButtons = document.querySelectorAll<HTMLButtonElement>(
        ".al-compareresults-xoutproduct",
    );
    Array.from(removeButtons).map((btn) => {
        btn.addEventListener("click", () => {
            checkColumnVisibility(selectedColumnsRange);
        });
    });

    const nav = document.getElementById("product-compare-slider-nav");
    let counter = 0;
    if (nav) {
        const prevNav = nav.children[0];
        const nextNav = nav.children[1];
        const disablebuttonClass = "disable";

        if (colCount < 3) {
            prevNav.classList.add("hide");
            nextNav.classList.add("hide");
        }

        prevNav.classList.add(disablebuttonClass);

        prevNav.addEventListener("click", () => {
            if (counter === 0) {
                return;
            }

            selectedColumnsRange = selectedColumnsRange.map((elem) => {
                elem--;
                return elem;
            });
            checkColumnVisibility(selectedColumnsRange);

            compareRows.map((row) => {
                // complex calculation due to inexplicably duped addEventListeners
                if (row.scrollLeft !== colWidth * (counter - 1)) {
                    row.scrollLeft -= colWidth;
                }
            });

            counter--;
            if (counter === 0) {
                prevNav.classList.add(disablebuttonClass);
            }

            if (nextNav.classList.contains(disablebuttonClass)) {
                nextNav.classList.remove(disablebuttonClass);
            }
        });

        nextNav.addEventListener("click", () => {
            if (counter > clickableCount) {
                return;
            }

            selectedColumnsRange = selectedColumnsRange.map((elem) => {
                elem++;
                return elem;
            });
            checkColumnVisibility(selectedColumnsRange);

            compareRows.map((row) => {
                // complex calculation due to inexplicably duped addEventListeners
                if (row.scrollLeft !== colWidth * (counter + 1)) {
                    row.scrollLeft += colWidth;
                }
            });

            counter++;
            if (counter === clickableCount) {
                nextNav.classList.add(disablebuttonClass);
            }

            if (prevNav.classList.contains(disablebuttonClass)) {
                prevNav.classList.remove(disablebuttonClass);
            }
        });
    }
};

const registerEventListeners_addChangeSelectionLink = () => {
    const addChangeSelectionLink = document.querySelector<HTMLLinkElement>(
        ".product-compare-page-block > a",
    );
    if (addChangeSelectionLink) {
        addChangeSelectionLink.addEventListener("click", () => {
            const state = store.getState();
            const selectedTileIDs = state.productcompare.selectedTileIDs;
            if (selectedTileIDs.length <= 4) {
                localStorage.setItem(
                    "selectedTileIDs",
                    JSON.stringify(selectedTileIDs),
                );
            }
        });
    }
};

const getTallestMattressDescriptionHeight = () => {
    const mattressDescriptionElementPath =
        ".product-selection-grid-block header:not(.product-selection-grid-block-header) > .description p";
    let MattressDescriptionTallestHeight = 0;
    Array.from(
        document.querySelectorAll<HTMLElement>(mattressDescriptionElementPath),
    ).forEach((n) => {
        n.setAttribute("style", "height:auto; min-height:auto;");
    });
    Array.from(
        document.querySelectorAll<HTMLElement>(mattressDescriptionElementPath),
    ).forEach((n) => {
        const contentHeight = n.offsetHeight;
        if (contentHeight > MattressDescriptionTallestHeight) {
            MattressDescriptionTallestHeight = contentHeight;
        }
    });
    Array.from(
        document.querySelectorAll<HTMLElement>(mattressDescriptionElementPath),
    ).forEach((n) => {
        n.style.height = `${MattressDescriptionTallestHeight / 16}em`;
    });
};

onReadyStateComplete.on(async () => {
    productCompareTileSlider();
    registerEventListeners_addChangeSelectionLink();
    getTallestMattressDescriptionHeight();

    window.addEventListener("resize", () => {
        getTallestMattressDescriptionHeight();
    });
});

window.addEventListener("scroll", () => {
    const firstRow = document.querySelector<Element>(".comparison-grid > .row");
    const gridElem = document.querySelector<Element>(".comparison-grid");

    if (
        firstRow &&
        gridElem &&
        firstRow.getClientRects()[0] &&
        gridElem.getClientRects()[0]
    ) {
        const targetStartPosition =
            firstRow.getClientRects()[0].bottom + window.pageYOffset - 100;
        const targetEndPosition =
            gridElem.getClientRects()[0].bottom + window.pageYOffset;
        if (
            window.scrollY < targetStartPosition ||
            window.scrollY > targetEndPosition
        ) {
            // This is for the top "Add to Cart" sticky configurator of the experimental PLP
            // Show the sticky configurator at the bottom threshold of the product header, when the sticky product header is hided in the comparison grid.
            hideTopStickyConfigurator(false);
        } else {
            // This is for the top "Add to Cart" sticky configurator of the experimental PLP
            // Hide the sticky configurator at the top threshold of the product header, when the sticky product header is displayed in the comparison grid.
            hideTopStickyConfigurator(true);
        }
    }
});
