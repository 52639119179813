import React from "react";
import { IStoreSearchFilters } from "tsi-common-react/src/apps/retail/models.interfaces";
import { IMiles, isoMiles } from "tsi-common-react/src/models/nominals";
import { IFilterChangeHandler } from "../models.interfaces";
import { LocatorFilterPopover } from "./LocatorFilters_Popover";

interface IProps {
    filterValues: IStoreSearchFilters;
    onChange: IFilterChangeHandler;
}

interface IState {
    filterValueInRedux: IMiles;
    value: IMiles;
}

export class DistanceFilter extends React.Component<IProps, IState> {
    state: IState = {
        filterValueInRedux: this.props.filterValues.distance,
        value: this.props.filterValues.distance,
    };

    private readonly distanceOptions = [10, 20, 30, 40, 50, 75, 100];
    private readonly filterElem = React.createRef<LocatorFilterPopover>();

    static getDerivedStateFromProps(
        props: IProps,
        state: IState,
    ): IState | null {
        if (props.filterValues.distance !== state.filterValueInRedux) {
            return {
                filterValueInRedux: props.filterValues.distance,
                value: props.filterValues.distance,
            };
        }
        return null;
    }

    private readonly onChange = (event: React.FormEvent<HTMLSelectElement>) => {
        this.setState({
            value: isoMiles.wrap(parseInt(event.currentTarget.value, 10)),
        });
    };

    private readonly onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.onChange({
            distance: this.state.value,
        });
        if (this.filterElem.current) {
            this.filterElem.current.onRequestClose();
        }
    };

    private readonly onAfterClose = () => {
        // Revert filter back to the state in Redux
        this.setState((s) => {
            return {
                ...s,
                value: s.filterValueInRedux,
            };
        });
    };

    render() {
        return (
            <LocatorFilterPopover
                ref={this.filterElem}
                id="distance"
                labelText={`${this.props.filterValues.distance} miles`}
                onAfterClose={this.onAfterClose}
            >
                <form
                    className="filters__dropdown filters__dropdown--distance"
                    onSubmit={this.onSubmit}
                >
                    <fieldset>
                        <legend className="filters__instruct">
                            Change the distance search radius.
                        </legend>
                        <div className="form__field filters__inputs">
                            <label htmlFor="bh-sl-maxdistance"></label>
                            <select
                                id="bh-sl-maxdistance"
                                className="form__select"
                                name="bh-sl-maxdistance"
                                aria-label="Max Distance"
                                onChange={this.onChange}
                                value={isoMiles.unwrap(this.state.value)}
                            >
                                {this.distanceOptions.map((opt) => {
                                    return (
                                        <option key={opt} value={opt}>
                                            {opt} miles
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="filters__actions sl-search__actions">
                            <input
                                className="filters__button button"
                                type="submit"
                                aria-label="Submit"
                            />
                        </div>
                    </fieldset>
                </form>
            </LocatorFilterPopover>
        );
    }
}
