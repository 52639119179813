import { IProductUUID } from "tsi-common-react/src/models/nominals";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { Dispatchers as BaseDispatchers } from "tsi-common-react/src/apps/retail/dispatchers";
import { ICMSFlagshipStorePage } from "./models.interfaces";
import {
    Action,
    IActionSetProductAvailability,
    IActionSetSelectedProduct,
    IActionAddFlagshipStorePages,
} from "./reducers.interfaces";

export class Dispatchers extends BaseDispatchers {
    public readonly setProductAvailability = (
        product: IProductUUID,
        stores: IRetailStoreWithDistance[],
    ) => {
        this.dispatch<IActionSetProductAvailability>({
            type: Action.SET_PRODUCT_AVAILABILITY,
            payload: {
                product: product,
                stores: stores,
            },
        });
    };

    public readonly setSelectedProduct = (product: IProductUUID) => {
        this.dispatch<IActionSetSelectedProduct>({
            type: Action.SET_SELECTED_PRODUCT,
            payload: product,
        });
    };

    public readonly addFlagshipStorePages = (
        pages: ICMSFlagshipStorePage[],
    ) => {
        this.dispatch<IActionAddFlagshipStorePages>({
            type: Action.ADD_FLAGSHIP_STORE_PAGES,
            payload: pages,
        });
    };
}
