import React from "react";
import classNames from "classnames";
import { UncontrolledPopover } from "tsi-common-react/src/common/Popover";

interface IProps {
    id: string;
    labelText: string;
    onAfterClose?: () => void;
    onAfterOpen?: () => void;
    children?: React.ReactNode;
}

interface IState {}

export class LocatorFilterPopover extends React.Component<IProps, IState> {
    private readonly popperElem = React.createRef<UncontrolledPopover>();

    public readonly onRequestClose = () => {
        if (this.popperElem.current) {
            this.popperElem.current.requestClose();
        }
    };

    render() {
        const labelClasses = classNames({
            filters__filter: true,
            [`filters__filter--${this.props.id}`]: true,
        });
        const modalClasses = classNames({
            filters__dropdown: true,
            [`filters__dropdown--${this.props.id}`]: true,
        });

        return (
            <UncontrolledPopover
                ref={this.popperElem}
                triggerContent={this.props.labelText}
                triggerBtnProps={{
                    className: labelClasses,
                }}
                popperOptions={{
                    placement: "bottom",
                }}
                modalProps={{
                    contentLabel: this.props.labelText,
                    style: {
                        overlay: {
                            backgroundColor: "transparent",
                        },
                    },
                }}
                onAfterOpen={this.props.onAfterOpen}
                onAfterClose={this.props.onAfterClose}
            >
                <div className={modalClasses}>{this.props.children}</div>
            </UncontrolledPopover>
        );
    }
}
