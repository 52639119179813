import React from "react";
import { t } from "ttag";
import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";
import { getDefaultFinancingPlan } from "../../../utils/financing";
import { strings } from "../../../localization";
import { Trans } from "../../../common/Trans";
import { formatAPR } from "../../../utils/format";

import styles from "./index.module.scss";

export const FinancingPreQualHero = () => {
    const defaultPlan = getDefaultFinancingPlan();
    const cardName = strings.get("FINANCING_CARD_NAME") || "";
    return (
        <section className={styles.root}>
            <div className={styles.wrapper}>
                {defaultPlan && (
                    <h2 className={styles.heading}>
                        <Trans
                            fmtString={interpolate(
                                t`%(apr)s APR for %(length)s Months<SUP>1</SUP>`,
                                {
                                    apr: formatAPR(defaultPlan.apr),
                                    length: `${defaultPlan.length}`,
                                },
                            )}
                            data={{
                                SUP: (content) => <sup key={1}>{content}</sup>,
                            }}
                        />
                    </h2>
                )}
                <p className={styles.copy}>
                    <Trans
                        fmtString={t`Check if you pre-qualify for a ${cardName}`}
                        data={{
                            SUP: (content) => <sup key={1}>{content}</sup>,
                        }}
                    />
                </p>
                <ul className={styles.list}>
                    <li className={styles.item}>{t`Instant Decision`}</li>
                    <li className={styles.item}>
                        {t`No Impact to your Credit Score`}
                    </li>
                </ul>
            </div>
        </section>
    );
};
