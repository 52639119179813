import { isElement } from "../../../utils/guards";

export const openPrintWindow = (container: HTMLDivElement) => {
    const width = 640;
    const height = 480;
    const options = [
        ["toolbar", "no"],
        ["location", "no"],
        ["directories", "no"],
        ["status", "no"],
        ["menubar", "no"],
        ["scrollbars", "no"],
        ["resizable", "yes"],
        ["width", width],
        ["height", height],
        ["top", screen.height / 2 - height / 2],
        ["left", screen.width / 2 - width / 2],
    ];
    const encodedOptions = options
        .map((pair) => {
            return pair.join("=");
        })
        .join(", ");
    const popup = window.open(
        "",
        "Wells Fargo Account Details",
        encodedOptions,
    );
    if (!popup) {
        return;
    }
    const messageText = isElement(container) ? container.innerHTML : container;
    if (document.head && popup.document.head) {
        popup.document.head.innerHTML = document.head.innerHTML;
    }
    popup.document.body.innerHTML = `
        <div>
            ${messageText}
            <button class="button no-print" onclick="javascript:window.print()">Print</button>
            <button class="button no-print" onclick="javascript:window.close()">Close</button>
        </div>
    `;
    popup.print();
};
