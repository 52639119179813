import React from "react";
import { BackendType } from "../../../models/financing";
import { WellsFargoTerms, Props as WellsFargoProps } from "./WellsFargoTerms";
import { FortivaTerms, Props as FortivaProps } from "./FortivaTerms";

type Props = WellsFargoProps &
    FortivaProps & {
        backendType: BackendType | null;
    };

export const FinancingFullTerms = (props: Props) => {
    if (props.backendType === BackendType.WELLSFARGO) {
        return <WellsFargoTerms {...props} />;
    }
    if (props.backendType === BackendType.FORTIVA) {
        return <FortivaTerms {...props} />;
    }
    return null;
};
