import { decorateElements } from "tsi-common-react/src/utils/react";

decorateElements(
    ".four-by-one-image-block__container--hover-active",
    (elems) => {
        for (const elem of elems) {
            const hover = elem.querySelector<HTMLElement>(
                ".four-by-one-image-block-hover",
            );
            const image = elem.querySelector<HTMLElement>(
                ".four-by-one-image-block__image",
            );
            if (!hover || !image) {
                return;
            }
            elem.addEventListener("mouseenter", () => {
                hover.style.opacity = "100";
                image.style.filter = "blur(5px)";
            });
            elem.addEventListener("mouseleave", () => {
                hover.style.opacity = "0";
                image.style.filter = "blur(0px)";
            });
            // Wait 500ms after attaching the event handlers before marking this element as loaded (and enabling
            // the CSS transitions). This prevents an initial "flash" of visible text as the text opacity transitions
            // from default to 0 (as the browser applies the styles for the .four-by-one-image-block-hover class).
            setTimeout(() => {
                hover.classList.add("four-by-one-image-block-hover--loaded");
            }, 500);
        }
    },
);
