import React from "react";
import { t } from "ttag";

export const FortivaDeniedMessage = () => (
    <>
        <p>
            {t`Unfortunately, Fortiva Retail Credit was unable to approve your application for credit at this time.`}
        </p>
        <p>
            {t`You will receive written notification of this denial in the mail within 30 days.`}
        </p>
    </>
);
