import React from "react";
import { t } from "ttag";
import Modal from "react-modal";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { check } from "tsi-common-react/src/models/utils";
import { ProductUpsellModalBlockSlide } from "tsi-common-react/src/models/streamfield-blocks";
import {
    IUpsellModalProps,
    IUpsellModalComponent,
} from "tsi-common-react/src/apps/configurator/models.interfaces";
import UpsellModalSlideCollection from "./UpsellModalSlideCollection";
import { ProductPage } from "../models";
import { focusElement } from "tsi-common-react/src/utils/keyboardFocus";
import iconXClose from "../../svg/x-close.svg";

interface IState {
    modalIsOpen: boolean;
}

export class UpsellModal
    extends React.Component<IUpsellModalProps, IState>
    implements IUpsellModalComponent
{
    public state: IState = {
        modalIsOpen: false,
    };

    public openModal(event?: React.MouseEvent<HTMLElement>) {
        if (event) {
            event.preventDefault();
        }

        document.body.setAttribute("style", "overflow-y: hidden");
        this.setState({
            modalIsOpen: true,
        });
    }

    private closeModal(
        event?: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) {
        if (event) {
            event.preventDefault();
        }

        document.body.setAttribute("style", "overflow: initial");
        this.setState({
            modalIsOpen: false,
        });
        this.props.onProceed();
    }

    // Ensure the bundle group selected in Wagtail has a corresponding bundle for the current product variant
    // If not, remove that slide
    private filterSlides(
        slides: ProductUpsellModalBlockSlide[],
    ): ProductUpsellModalBlockSlide[] {
        const configuredBundleGroupIDs: number[] = [];
        this.props.bundles.map((bundle) => {
            configuredBundleGroupIDs.push(bundle.bundle_group.id);
        });
        const filteredSlides = slides.filter((slide) => {
            return (
                slide.slide_bundle?.id === 0 ||
                (slide.slide_bundle?.id &&
                    configuredBundleGroupIDs.indexOf(slide.slide_bundle.id) !==
                        -1)
            );
        });
        return filteredSlides;
    }

    render() {
        const detailContainerElem = document.querySelector<HTMLDivElement>(
            "#product-detail-container[data-cms]",
        );
        if (!detailContainerElem) {
            return null;
        }
        const cmsData = check(
            ProductPage.decode(
                JSON.parse(detailContainerElem.dataset.cms || ""),
            ),
        );
        const bundleModule = cmsData.bundle_modal_serialized;
        if (!bundleModule || bundleModule.length === 0) {
            if (this.state.modalIsOpen) {
                this.props.onProceed();
            }
            return null;
        }
        const bundleData = bundleModule[0];
        const headerTitle = bundleData.header_title;
        const headerCopy = bundleData.header_copy;
        const headerTitleMobile = bundleData.header_title_mobile;
        const headerCopyMobile = bundleData.header_copy_mobile;
        const slides = this.filterSlides(bundleData.slides || []);
        const mainCTACopy = bundleData.cta_copy;

        const modalStyle = {
            overlay: {
                backgroundColor: "rgba(0,0,0,0.8)",
            },
            content: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
                overflow: "hidden",
                boxShadow: "none",
                border: 0,
            },
        };

        const windowWidth = window.innerWidth;
        if (windowWidth < 500) {
            modalStyle.content.overflow = "scroll";
        }

        const classes = classNames({
            "upsell-modal": windowWidth >= 500,
            "upsell-modal-phone": windowWidth < 500,
            "al-upsell-modal": true,
        });

        return (
            <div>
                <Modal
                    aria={{ modal: true }}
                    contentLabel={t`You might also like this product.`}
                    className={classes}
                    overlayClassName={"upsell-modal__overlay"}
                    htmlOpenClassName={"upsell-modal__html-overlay"}
                    style={modalStyle}
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={() => {
                        focusElement(".pdp-modal__close");
                    }}
                    onRequestClose={(e) => {
                        this.closeModal(e);
                    }}
                    role="dialog"
                >
                    <button
                        aria-label={t`Close`}
                        className="pdp-modal__close"
                        onClick={(e) => {
                            this.closeModal(e);
                        }}
                    >
                        <SVG
                            aria-hidden="true"
                            className="pdp-modal__close-icon"
                            src={iconXClose}
                            title={t`Close Icon`}
                        />
                    </button>
                    <div className="upsell-modal__container">
                        <div className="upsell-modal__header">
                            <span className="upsell-modal__header-copy upsell-modal__header-copy--desktop">
                                <h3 className="upsell-modal__title">
                                    {headerTitle}
                                </h3>
                                <p className="upsell-modal__title-copy">
                                    {headerCopy}
                                </p>
                            </span>
                            <span className="upsell-modal__header-copy upsell-modal__header-copy--mobile">
                                <h3 className="upsell-modal__title">
                                    {headerTitleMobile}
                                </h3>
                                <p className="upsell-modal__title-copy">
                                    {headerCopyMobile}
                                </p>
                            </span>
                        </div>
                        <UpsellModalSlideCollection
                            slides={slides}
                            bundles={this.props.bundles}
                            onProceed={this.props.onProceed}
                        />
                        <div className="upsell-modal__cta">
                            <a
                                href="#"
                                className="upsell-modal__cta-button"
                                onClick={this.props.onProceed}
                            >
                                {mainCTACopy}
                            </a>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
