import React from "react";
import { FinancingPlan } from "tsi-common-react/src/models/financing";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

interface IProps {
    plans: FinancingPlan[];
}

interface IState {}

export class BasketFinePrint extends React.Component<IProps, IState> {
    render() {
        if (this.props.plans.length <= 0) {
            return null;
        }
        return (
            <div
                id="basket-lines__fine-print"
                className="basket-lines__fine-print"
                dangerouslySetInnerHTML={{
                    __html: getPageSetting("basket-financing-fine-print"),
                }}
            ></div>
        );
    }
}
