import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

onDOMContentLoaded.on(async () => {
    const productListNav = document.querySelector(".product-list-nav__list");
    if (!productListNav) {
        return;
    }
    const Flickity = (await import("flickity")).default;
    const flickity = new Flickity(productListNav, {
        watchCSS: true,
        draggable: true,
        wrapAround: true,
        prevNextButtons: false,
        pageDots: true,
    });
    return flickity;
});
